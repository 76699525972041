import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import Property from "./pages/Property";
import Profile from "./pages/Profile";
import PropertyDetails from "./pages/PropertyDetails";
import Admin from "./pages/Admin";
import { ToastContainer } from "react-toastify";
import "./styles/main_styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import Investments from "./pages/Investments";
import List from "./pages/List";
import Listings from "./pages/Listings";
import ListedPropertyDetails from "./pages/ListedPropertyDetails";
import Transaction from "./pages/Transaction";
import Withdraw from "./pages/Withdraw";
import Account from "./pages/account";
import { useEffect } from "react";
import ContactUs from "./pages/ContactUs";
import Exchange from "./pages/Exchange";
import Register from "./pages/Register";
import AdminLogin from "./pages/AdminLogin";
import AdminProfile from "./pages/AdminProfile";

function App() {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = `//${window.location.host}/assests/js/main.js`;
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = `//${window.location.host}/assests/js/main.js`;
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/marketplace" element={<Marketplace />} /> */}
        <Route exact path="/property" element={<Property />} />
        <Route exact path="/loyalty-program" element={<PropertyDetails />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/transactions" element={<Transaction />} />
        <Route exact path="/withdraw" element={<Withdraw />} />
        <Route exact path="/account" element={<Account />} />
        {/* <Route exact path="/property-details" element={<PropertyDetails />} /> */}
        {/* <Route exact path="/admin" element={<Admin />} /> */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/investments" element={<Investments />} />
        {/* <Route exact path="/list/:propertyId" element={<List />} /> */}
        {/* <Route exact path="/listings" element={<Listings />} /> */}
        <Route exact path="/exchange" element={<Exchange />} />
        {/* <Route exact path="/listed-property-details/:listingId" element={<ListedPropertyDetails />} /> */}
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/admin/login" element={<AdminLogin />} />
        <Route exact path="/admin/profile" element={<AdminProfile />} />
      </Routes>
    </>
  );
}

export default App;
