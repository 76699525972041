import React, { useEffect, useState } from "react";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import AdminHeader from "../components/AdminHeader";
import { setAdmin } from "../app/mainSlice/mainSlice";
import { useDispatch, useSelector } from "react-redux";

const AdminProfile = () => {
  const dispatch = useDispatch();

  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("titos-admin");
      if (userDetails) {
        dispatch(setAdmin(JSON.parse(userDetails)));
      } else {
        window.location.href = '/admin/login';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  const signOut = () => {
    try {
      localStorage.removeItem("titos-user");
      localStorage.removeItem("titos-token");
      window.location.href = '/admin/login';
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const [withdrawRequestList, setWithdrawRequestList] = useState();

  const getWithDrawData = async () => {
    const token = localStorage.getItem('titos-admin-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/withdraw-request`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await res.json();
    setWithdrawRequestList(data.data);
  };

  useEffect(() => {
    getWithDrawData();
  }, []);

  const updateStatus = async (approve, uuid) => {
    const token = localStorage.getItem('titos-admin-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/withdraw-request`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'is_approved': approve, 'request_uuid': uuid }),
    });
    const data = await res.json();
    console.log(data);
    if (data) {
      getWithDrawData();
    }
  };

  useEffect(() => {
    if (withdrawRequestList) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#withdrawRquestTable');`;
      document.body.appendChild(script);
    }
  }, [withdrawRequestList]);

  return (
    <>
      <AdminHeader />

      {/* <!-- ==== dashboard section start ==== --> */}
      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <div className="sidebar">
                  <a href="javascript:void(0)" className="close__sidebar">
                    <i className="fa-solid fa-xmark"></i>
                  </a>
                  <div className="sidenav__wrapper">
                    <ul>
                      <li>
                        <a
                          onClick={() => {
                            // window.location.href = '/profile';
                          }}
                        // className={currentPage === '/profile' && "sidenav__active"}
                        >
                          <img src="../assets/images/icons/dashboard.png" alt="Dashboard" /> Withdraw Request
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <ul className="logout">
                      <li>
                        <a onClick={signOut}>
                          <img src="../assets/images/icons/logout.png" alt="Logout" /> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xxl-9">
                <div class="main__content">
                  <div class="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" class="collapse__sidebar__btn">
                      <i class="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div class="main__content-dashboard">
                    <div class="breadcrumb-dashboard">
                      <h5>Withdraw Requests</h5>
                    </div>
                    <div class="custome-table">
                      <div class="intro">
                        <h5>Withdraw Requests</h5>
                      </div>
                      <table className="table table-striped" id="withdrawRquestTable">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email Id</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: '360px', overflowY: 'scroll' }}>
                          {withdrawRequestList?.map((request) => {
                            return (
                              <tr>
                                <td>{request?.user.first_name} {request?.user.last_name}</td>
                                <td>{request?.user.email}</td>
                                <td>{request?.created_at}</td>
                                <td>{request?.amount}</td>
                                <td>
                                  {request?.is_approved == null
                                    ? <div className="d-flex admin-approve-reject">
                                      <img src="../assets/images/approve.svg" alt="Approve" onClick={() => updateStatus(true, request?.uuid)} />
                                      <img src="../assets/images/reject.svg" alt="Reject" onClick={() => updateStatus(false, request?.uuid)} />
                                    </div>
                                    : request?.is_approved == true
                                      ? "Approved"
                                      : "Rejected"
                                  }
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      {/* <!-- ==== #dashboard section end ==== --> */}

    </>
  );
};

export default AdminProfile;