import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAdmin } from "../app/mainSlice/mainSlice";

const AdminHeader = () => {
  const dispatch = useDispatch();

  const admin = useSelector(state => state.main.admin);

  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("titos-admin");
      if (userDetails) {
        dispatch(setAdmin(JSON.parse(userDetails)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("titos-admin");
      localStorage.removeItem("titos-admin-token");
      dispatch(setAdmin(null));
      window.location.href = '/admin/login';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <a href="/" className="navbar-brand">
              <img src="/assets/images/titos-black.png" alt="Logo" className="logo" />
            </a>
            <div className="navbar__out order-2 order-xl-3">
              {admin ? (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href = '/admin/profile'
                    }}
                  >
                    Profile
                  </span>
                  <button onClick={signOut} className="button text-white">
                    Logout
                  </button>
                </>
              ) : (
                <div className="nav__group__btn">
                  <button
                    className="button button--effect text-white"
                    onClick={() => window.location.href = '/admin/login'}
                  >
                    Login <i className="fa-solid fa-arrow-right-long"></i>
                  </button>
                </div>
              )}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#primaryNav"
                aria-controls="primaryNav"
                aria-expanded="false"
                aria-label="Toggle Primary Nav"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default AdminHeader;