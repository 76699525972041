import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SideNavbar from "../components/SideNavbar";

const Investments = () => {
  const [transaction, setTransaction] = useState();

  const getTransactions = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}main/user-investments`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await res.json();
    console.log(data.data);
    setTransaction(data?.data)
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(0);
  const [properties, setProperties] = useState([]);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);

  const options = {
    colors: ["#1FAA5C"],
    chart: {
      type: "area",
      height: 530,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Invested",
        data: [400, 350, 300, 250, 300, 150, 200],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      colors: ["#1FAA5C"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    markers: {
      hover: {
        size: 8,
        strokeWidth: 4,
        colors: ["#ffffff"],
        strokeColors: ["#51CCA9"],
      },
    },
    xaxis: {
      axisTicks: {
        show: true,
      },
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yaxis: {
      show: true,
      opposite: false,
      labels: {
        formatter: function (value) {
          return "$" + value;
        },
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          chart: {
            maxWidth: "100%",
            height: 250,
            sparkline: {
              enabled: false,
            },
          },
        },
      },
    ],
  };

  const signOut = () => {
    try {
      localStorage.removeItem("rhoof-user");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const [params] = useSearchParams();

  useEffect(() => {
    if (transaction) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#investmentTable');`;
      document.body.appendChild(script);
    }
  }, [transaction]);

  return (
    <>
      <Header />

      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <SideNavbar />
              </div>
              <div class="col-xxl-9">
                <div class="main__content">
                  <div class="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" class="collapse__sidebar__btn">
                      <i class="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div class="main__content-dashboard">
                    <div class="breadcrumb-dashboard">
                      <h5>Investments</h5>
                      <div>
                        <a href="index.html">Home</a>
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <a href="javascript:void(0)">Investments</a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="custome-table">
                          <div class="intro">
                            <h5>Transactions</h5>
                          </div>
                          <table className="table table-striped" id="investmentTable">
                            <thead>
                              <tr>
                                <th>Project</th>
                                <th>Amount Invested</th>
                                <th>Payment Type</th>
                                <th>Date / Time</th>
                              </tr>
                            </thead>
                            <tbody style={{ maxHeight: '360px', overflowY: 'scroll' }}>
                              {transaction?.map((trans) => {
                                if (trans.is_exchange) return <></>;
                                return (
                                  <tr>
                                    <td>
                                      {/* <img src="assets/images/about-video-illustration.jpg" alt="Investment" className="table-titos-img" /> */}
                                      Titos
                                    </td>
                                    <td>₹{trans.total_amount}</td>
                                    <td>{trans.payment_option}</td>
                                    <td>{trans.created_at}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div class="col-lg-4">
                        <div class="investment-sidebar">
                          <div class="statistics">
                            <h5>Statistics</h5>
                            <hr />
                            <div class="group">
                              <img src="assets/images/icons/wallet.png" alt="Wallet" />
                              <div>
                                <h4>₹537,00</h4>
                                <p>Monthly Income</p>
                              </div>
                            </div>
                          </div>
                          <div class="explore mt-5">
                            <img src="assets/images/icons/explore.png" alt="Explore" />
                            <div class="group">
                              <h6>Explore More</h6>
                              <p class="secondary">Investment opportunities</p>
                              <a href="registration.html"
                                class="button button--effect">Explore</a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div class="dashboard-single__box investment-single-box">
                      <div class="intro">
                        <h5 class="investo">Investment Chart</h5>
                        <a href="#">Generate Report</a>
                      </div>
                      <div id="investmentChartTwo"></div>
                    </div> */}
                    <div class="dashboard-single__box mt-5">
                      <h5 class="investo">Investment Chart</h5>
                      <div id="investmentChart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investments;
