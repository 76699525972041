import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import './checkoutForm.css';

export default function CheckoutForm({ closeModal1, closeModal2 }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/investments`,
      },
      redirect: 'if_required',
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
      toast.error(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      const token = localStorage.getItem('titos-token');
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}main/stripe-payment-callback`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transaction_id: paymentIntent.id })
      });
      const data = await res.json();
      console.log(data);
      if (data.status) {
        setMessage('Payment Successful');
        closeModal1();
        closeModal2();
        toast.success('Payment Successful');
        setTimeout(() => {
          window.location.href = '/investments';
        }, 2000);
      } else {
        console.log('backend request fail');
        closeModal1();
        closeModal2();
        toast.error('Something went wrong. Please contact admin.')
      }
    } else {
      setMessage("An unexpected error occured.");
      toast.error("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}