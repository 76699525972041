import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import SideNavbar from "../components/SideNavbar";
import { useDispatch } from "react-redux";
import { setUser } from "../app/mainSlice/mainSlice";

const phoneRegExp =
  /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4,5}\)?)?$/;

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name must have at least 2 characters")
    .max(100, "Name can't be longer than 100 characters")
    .required("Name is required"),
  username: yup
    .string()
    .min(2, "Username must have at least 2 characters")
    .max(10, "Username can't be longer than 10 characters")
    .required("Username is required"),
  countryCode: yup.string(),
  mobile: yup.string().matches(phoneRegExp, "Mobile number is not valid."),
});

const Profile = () => {
  const dispatch = useDispatch();

  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("titos-user");
      if (userDetails) {
        dispatch(setUser(JSON.parse(userDetails)));
      } else {
        window.location.href = '/login';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  const [transaction, setTransaction] = useState();

  const getTransactions = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}main/user-investments`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await res.json();
    console.log(data.data);
    setTransaction(data?.data)
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const [myInvestment, setMyInvestment] = useState();

  useEffect(() => {
    if (transaction) {
      const temp = transaction?.reduce((sum, ele) => sum + ele?.total_amount, 0);
      setMyInvestment(temp);
    }
  }, [transaction]);

  const [purchasedShare, setPurchasedShare] = useState();

  const getPurchasedShare = () => {
    if (transaction) {
      const temp = transaction?.reduce((sum, ele) => sum + ele?.share_count, 0);
      setPurchasedShare(temp);
    }
  };

  useEffect(() => {
    getPurchasedShare();
  }, [transaction]);

  const [balance, setBalance] = useState();

  const getProfile = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/get-profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await res.json();
    setBalance(data.data.wallet_balance);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Header />

      {/* <!-- ==== dashboard section start ==== --> */}
      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <SideNavbar />
              </div>
              <div class="col-xxl-9">
                <div class="main__content">
                  <div class="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" class="collapse__sidebar__btn">
                      <i class="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div class="main__content-dashboard">
                    <div class="row">
                      <div class="col-lg-5 mt-4">
                        <div class="main__content-dashboard__chart">
                          <div class="balance-report__wrapper dashboard-single__box">
                            <div class="balance-report">
                              <div>
                                <h4>{purchasedShare}</h4>
                                <p class="secondary">Total Share Purchased</p>
                              </div>
                              {/* <div class="dashboard-balance-report">
                                <select class="select-balance-report">
                                  <option value="last">Last Month</option>
                                  <option value="january">January</option>
                                  <option value="february">February</option>
                                  <option value="march">March</option>
                                  <option value="april">April</option>
                                  <option value="may">May</option>
                                  <option value="june">June</option>
                                  <option value="july">July</option>
                                  <option value="august">August</option>
                                  <option value="september">September</option>
                                  <option value="october">October</option>
                                  <option value="november">November</option>
                                  <option value="december">December</option>
                                </select>
                              </div> */}
                            </div>
                            <hr />
                            <div class="group">
                              <div class="group-inner">
                                <p>Amount Invested</p>
                                <h6><img src="assets/images/icons/finance-management.jpg"
                                  alt="Invested" />₹{myInvestment}</h6>
                              </div>
                              <div class="group-inner">
                                <p>Total Earnings</p>
                                <h6><img src="assets/images/icons/investment.jpg"
                                  alt="Earned" />₹{balance}</h6>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-7 mt-4">
                        <div class="main__content-dashboard__sidebar">
                          <div class="dashboard-single__box">
                            <div class="intro">
                              <h5>New Investments</h5>
                              <a href="/investments">Show All<i
                                class="fa-solid fa-arrow-right-long"></i></a>
                            </div>
                            {transaction?.length == 0
                              ? <div className="text-center">No data found</div>
                              : transaction?.length == 1
                                ? <div class="new-invest">
                                  <div class="poster">
                                    <a href="javascript:void(0)">
                                      <img src="assets/images/about-video-illustration.jpg" alt="San Francisco" style={{ width: '180px', height: '110px' }} />
                                    </a>
                                  </div>
                                  <div class="invest-content">
                                    <div class="item__head__left">
                                      <h6>Titos</h6>
                                      <div class="progress__type progress__type--two">
                                        <div class="progress">
                                          <div class="progress-bar" role="progressbar"
                                            aria-valuenow="25" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                        </div>
                                        <div class="project__info">
                                          <p class="project__has"><span
                                            class="project__has__investors">{transaction?.[`${transaction?.length - 1}`]?.share_count} shares</span> | <span
                                              class="project__has__investors__amount">₹
                                              {transaction?.[`${transaction?.length - 1}`]?.total_amount}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : <>
                                  <div class="new-invest">
                                    <div class="poster">
                                      <a href="javascript:void(0)">
                                        <img src="assets/images/about-video-illustration.jpg" alt="San Francisco" style={{ width: '180px', height: '110px' }} />
                                      </a>
                                    </div>
                                    <div class="invest-content">
                                      <div class="item__head__left">
                                        <h6>Titos</h6>
                                        <div class="progress__type progress__type--two">
                                          <div class="progress">
                                            <div class="progress-bar" role="progressbar"
                                              aria-valuenow="25" aria-valuemin="0"
                                              aria-valuemax="100"></div>
                                          </div>
                                          <div class="project__info">
                                            <p class="project__has"><span
                                              class="project__has__investors">{transaction?.[`${transaction?.length - 1}`]?.share_count} shares</span> | <span
                                                class="project__has__investors__amount">₹
                                                {transaction?.[`${transaction?.length - 1}`]?.total_amount}</span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="new-invest">
                                    <div class="poster">
                                      <a href="javascript:void(0)">
                                        <img src="assets/images/about-video-illustration.jpg" alt="San Francisco" style={{ width: '180px', height: '110px' }} />
                                      </a>
                                    </div>
                                    <div class="invest-content">
                                      <div class="item__head__left">
                                        <h6>Titos</h6>
                                        <div class="progress__type progress__type--two">
                                          <div class="progress">
                                            <div class="progress-bar" role="progressbar"
                                              aria-valuenow="25" aria-valuemin="0"
                                              aria-valuemax="100"></div>
                                          </div>
                                          <div class="project__info">
                                            <p class="project__has"><span
                                              class="project__has__investors">{transaction?.[`${transaction.length - 2}`]?.share_count} shares</span> | <span
                                                class="project__has__investors__amount">₹
                                                {transaction?.[`${transaction.length - 2}`]?.total_amount}</span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-single__box mt-5">
                      <h5 class="investo">Investment Chart</h5>
                      <div id="investmentChart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ==== #dashboard section end ==== --> */}

    </>
  );
};

export default Profile;
