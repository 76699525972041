import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setSidebar } from "../app/mainSlice/mainSlice";

const SideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sidebar = useSelector(state => state.main.sidebar);

  const currentPage = window.location.pathname;
  
  const signOut = () => {
    try {
      localStorage.removeItem("titos-user");
      localStorage.removeItem("titos-token");
      window.location.href = '/';
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sidebar">
      <a href="javascript:void(0)" className="close__sidebar">
        <i className="fa-solid fa-xmark"></i>
      </a>
      <div className="sidenav__wrapper">
        <ul>
          <li>
            <a
              onClick={() => {
                // dispatch(setSidebar('profile'));
                // navigate('/profile');
                window.location.href = '/profile';
              }}
              className={currentPage === '/profile' && "sidenav__active"}
            >
              <img src="assets/images/icons/dashboard.png" alt="Dashboard" /> Dashboard
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                // dispatch(setSidebar('investments'));
                // navigate('/investments');
                window.location.href = '/investments';
              }}
              className={currentPage === '/investments' && "sidenav__active"}
            >
              <img src="assets/images/icons/investments.png" alt="Investments" />
              Investments
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                // dispatch(setSidebar('transactions'));
                // navigate('/transactions');
                window.location.href = '/transactions';
              }}
              className={currentPage === '/transactions' && "sidenav__active"}
            >
              <img src="assets/images/icons/transactions.png" alt="Transactions" />
              Transactions
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                // dispatch(setSidebar('withdraw'));
                // navigate('/withdraw');
                window.location.href = '/withdraw';
              }}
              className={currentPage === '/withdraw' && "sidenav__active"}
            >
              <img src="assets/images/icons/withdraw.png" alt="Withdraw" /> Withdraw
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                // dispatch(setSidebar('account'));
                // navigate('/account');
                window.location.href = '/account';
              }}
              className={currentPage === '/account' && "sidenav__active"}
            >
              <img src="assets/images/icons/account.png" alt="Account" /> Account
            </a>
          </li>
        </ul>
        <hr />
        <ul className="logout">
          <li>
            <a onClick={signOut}>
              <img src="assets/images/icons/logout.png" alt="Logout" /> Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideNavbar;