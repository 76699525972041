import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import { Tooltip } from "antd";
import { Modal } from "antd";
import useRazorpay from "react-razorpay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";

const stripePromise = loadStripe(
  "pk_test_51N0fJ1SFc5R7MIImhX5ZbSfh7MWqflIkfXlt6YwzFxdKdjXHkeJG1kruRPUmlYtxPMtP6sRrCDicqiGIGG4V6tJm00EUaalhJX"
);

function PropertyDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const [isModal2Open, setIsModal2Open] = useState(false);
  const showModal2 = () => setIsModal2Open(true);
  const handleOk2 = () => setIsModal2Open(false);
  const handleCancel2 = () => setIsModal2Open(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [properties, setProperties] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const schema = yup.object().shape({
    noOfShares: yup
      .number("No of shares should be a valid number.")
      .integer("No of shares should be a interger number.")
      .positive("No of shares should be a positive interger number.")
      .test(
        "checkMaxValue",
        "Entered no of shares is not available.",
        function (value) {
          console.log(value);
          if (value > propertyDetails.noOfShares) {
            return false;
          }
          return true;
        }
      )
      .required("No of shares is required."),
  });

  const [input, setInput] = useState({
    investedAmount: "",
    totalFees: "",
    amount: 0,
  });

  const [numberOfShare, setNumberOfShare] = useState();

  const propertyId = searchParams.get("property");

  const getPropertyData = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_ROOT_URL}main/club/63e43c1c-7a22-4b71-a77d-3ef7e13e0d55`
    );
    const data = await res.json();
    console.log(data);
    setPropertyDetails(data.data);
  };

  const [mail, setMail] = useState();
  const [firstName, setFirstName] = useState();
  const [mobile, setMobile] = useState();

  const getProfile = async () => {
    const token = localStorage.getItem("titos-token");
    const res = await fetch(
      `${process.env.REACT_APP_API_ROOT_URL}account/get-profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    console.log(data);
    setFirstName(data.data.first_name);
    setMobile(data.data.phone);
    setMail(data.data.email);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const roundNumber = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  function handleValidInput(event) {
    console.log(event.target.valueAsNumber);
    if (
      event.target.valueAsNumber &&
      event.target.valueAsNumber <= propertyDetails.available_share
    ) {
      const investedAmount = roundNumber(
        event.target.valueAsNumber * parseInt(propertyDetails.price_per_share)
      );
      const totalFees = roundNumber(investedAmount * 0.01);
      const amount = roundNumber(investedAmount + totalFees);
      setInput({
        investedAmount,
        totalFees,
        amount,
      });
    } else {
      setInput({
        investedAmount: "",
        totalFees: "",
        amount: 0,
      });
    }
  }

  const Razorpay = useRazorpay();

  const handlePayment = async () => {
    try {
      const token = localStorage.getItem("titos-token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ROOT_URL}main/init-payment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            club_id: propertyDetails.uuid,
            share_count: 1,
          }),
        }
      );
      const data = await res.json();
      console.log(data.data);
      let orderInfo = {};
      orderInfo = data?.data;

      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: "INR",
        name: "Titos",
        description: "Titos Transaction",
        image:
          window.location.href.replace("/loyalty-program", "") +
          "/assets/images/titos-black.svg",
        order_id: orderInfo.razorpay_order_id,
        handler: async function (response) {
          try {
            const res2 = await fetch(
              `${process.env.REACT_APP_API_ROOT_URL}main/payment-callback`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(response),
              }
            );
            const data2 = await res2.json();
            console.log(data2);
            setIsModalOpen(false);
            toast.success("Payment Successful");
            setTimeout(() => {
              window.location.href = "/investments";
            }, 1500);
          } catch (error) {
            console.log(error);
            toast.error("Payment Failed");
          }
        },
        prefill: {
          name: { firstName },
          email: { mail },
          contact: { mobile },
        },
        theme: {
          color: "#ff0000",
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  // const getProperties = () => {
  //   axios
  //     .get(process.env.REACT_APP_API_ROOT_URL + "property")
  //     .then((response) => {
  //       let { data } = response;
  //       if (data) {
  //         data = data.filter((property) => property.id !== propertyId);
  //       }
  //       setProperties(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Please try after sometime.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });
  // };

  useEffect(() => {
    getPropertyData();
    // getProperties();
  }, []);

  const setOccupancy = (occ) => {
    const investedShare = Math.ceil(propertyDetails.available_share * occ);
    setNumberOfShare(investedShare);
    const investedAmount = investedShare * propertyDetails.price_per_share;
    const totalFees = roundNumber(investedAmount * 0.01);
    const amount = roundNumber(investedAmount + totalFees);
    setInput({
      investedAmount,
      totalFees,
      amount,
    });
  };

  const [clientSecret, setClientSecret] = useState(null);

  const stripePayment = async () => {
    try {
      showModal2();
      const token = localStorage.getItem("titos-token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ROOT_URL}main/init-stripe-payment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            club_id: "5b4edf9c-6eb0-47cc-9d8c-ea42f0e51e7a",
            share_count: numberOfShare,
          }),
        }
      );
      const data = await res.json();
      console.log(data);
      setClientSecret(data.data.client_secret);
    } catch (error) {
      console.log(error);
    }
  };

  const investNow = () => {
    const token = localStorage.getItem("titos-token");
    if (token) {
      showModal();
    } else {
      toast.error("Please login first");
    }
  };

  const user = useSelector((state) => state.main.user);

  const handleLogin = () => {
    if (user) {
      window.location.href = "/profile";
    } else {
      window.location.href = "/register";
    }
  };

  return (
    <>
      <Header />

      {/* <!-- ==== details section start ==== --> */}
      <div
        className="property__details__banner bg__img clear__top"
        style={{
          backgroundImage:
            "url(/assets/images/banner/property-details-banner.jpg)",
        }}
      />

      <section className="p__details faq section__space__bottom">
        <div className="container">
          <div className="p__details__area">
            <a
              href="#gallery"
              className="button button--effect button--secondary"
            >
              <i className="fa-solid fa-images"></i> Browse Gallery
            </a>
            <div className="row">
              <div className="col-lg-7 order-2 order-lg-1">
                <div className="p__details__content">
                  <div className="intro">
                    <h3>Titos</h3>
                    <p>
                      <i className="fa-solid fa-location-dot"></i> No.7/5, Titos
                      Ln, Saunta Vaddo, Baga, Goa
                    </p>
                  </div>
                  <div className="group__one">
                    <h4>
                      TITO'S PRIVILEGE CARD : UNLOCK EXTRAORDINARY EXPERIENCES
                    </h4>
                    <p>
                      At TITOS, we offer more than just a membership; we provide
                      an exclusive Tito's Privilege Card to a world of
                      unforgettable experiences. Your cardholdership ensures
                      access to many benefits that enrich your nightlife and
                      beyond.
                    </p>
                  </div>
                  <div className="group__two" id="howitworks">
                    <h5>
                      WHY OWN A TITO’S PRIVILEGE CARD : DISCOVER THE PERKS
                    </h5>
                    <ul className="mt-4">
                      <li className="fw-normal">
                        <img src="/assets/images/check.png" alt="Check" />
                        <spna>
                          <b>Lifetime Access to Premier Nightlife:</b> As a
                          TITO’s Privilege Card holder, relish a lifetime of
                          complimentary entry to our esteemed nightclubs.
                          Immerse yourself in the vibrant ambiance and
                          world-class entertainment at TITO’s whenever you
                          desire.
                        </spna>
                      </li>
                      <li className="fw-normal">
                        <img src="/assets/images/check.png" alt="Check" />
                        <spna>
                          <b>25% Discount on All Drinks:</b> Enjoy your favorite
                          drinks with a generous 25% discount, adding delight to
                          your nights out.
                        </spna>
                      </li>
                      <li className="fw-normal">
                        <img src="/assets/images/check.png" alt="Check" />
                        <spna>
                          <b>Food Discount:</b> Exclusive for cardholders,
                          relish a 15% discount on all culinary delights.
                        </spna>
                      </li>
                      <li className="fw-normal">
                        <img src="/assets/images/check.png" alt="Check" />
                        <spna>
                          <b>Exclusive Access to TITOS Goa Events:</b>{" "}
                          Experience the best of TITO’s Goa events with
                          exclusive card benefits at partner establishments,
                          including but not limited to hotels, other clubs,
                          restaurants, spas, cafes, shopping outlets, boutiques,
                          water sports, tours, and more. . Whether you're
                          joining or simply planning to attend, your TITO’s
                          Privilege Card ensures a privileged experience.
                        </spna>
                      </li>
                      <li className="fw-normal">
                        <img src="/assets/images/check.png" alt="Check" />
                        <spna>
                          <b>Recognition and Rewards:</b> Receive well-deserved
                          recognition for your dedication and contributions to
                          Titos Goa.
                        </spna>
                      </li>
                      <li
                        className="fw-normal"
                        style={{
                          fontSize: "14px",
                          fontStyle: "italic",
                          lineHeight: "20px",
                        }}
                      >
                        Join the ranks of our exclusive TITO’S loyalty program
                        and actively contribute to the success of Titos Goa
                        while enjoying a unique and rewarding experience.
                      </li>
                    </ul>
                  </div>
                  <div className="terms">
                    <h5>TITO’S PRIVILEGE CARD BENEFITS:</h5>
                    <div className="terms__wrapper">
                      <div className="terms__single">
                        <img
                          src="/assets/images/icons/investors.png"
                          alt="Maximum Loan"
                        />
                        <p>Affordable Access</p>
                        <h5 className="neutral-bottom">50%</h5>
                      </div>
                      <div className="terms__single">
                        <img
                          src="/assets/images/icons/completed.png"
                          alt="Charge"
                        />
                        <p>Beverages Discounts</p>
                        <h5 className="neutral-bottom">25%</h5>
                      </div>
                      <div className="terms__single">
                        <img
                          src="/assets/images/icons/annual-return.png"
                          alt="Annual"
                        />
                        <p>Food Discounts</p>
                        <h5 className="neutral-bottom">15%</h5>
                      </div>
                    </div>
                  </div>
                  <div className="owner">
                    {/* <img src="/assets/images/owner.png" alt="Owner" /> */}
                    <div>
                      <h5>Titos Rewards Point</h5>
                      <p>
                        ELIGIBILITY: Tito's Rewards Point, a loyalty program
                        rewarding bookings at TITO’s Club, is free for
                        individuals who purchased TITO’s PRIVILEGE CARD.
                      </p>
                      <p>
                        BENEFITS : Earn points for bookings, redeem for
                        exclusive rewards, and enjoy recognition, discounts at
                        partner establishments, and more.
                      </p>
                    </div>
                  </div>
                  <div className="group__two">
                    <h5>Join Tito's Goa: Clubbing Legacy Since 1971</h5>
                    <p>
                      Titos Goa, a homegrown sensation that began as a humble
                      shack, has evolved into a powerhouse brand valued at 1200
                      crore today. Our journey from the sands of Goa to the
                      global stage is just beginning, and you can be a part of
                      this historic venture.
                    </p>
                    <p>
                      Join our global journey, with 1 million of 2.4 million
                      exclusive Tito’s Privilege Card memberships available.
                      Priced at 10,000 INR, a 50% discount offers it at 5,000
                      INR for a limited time.
                    </p>
                    <p>
                      Titos Rewards Point, an online loyalty program, rewards
                      digital payments for TITO’s Privilege Card at
                      titos.global, offering incentives and benefits for
                      hospitality clubbing.
                    </p>
                    <p>
                      Join online to secure your place in the legacy of Titos
                      Goa.
                    </p>
                    <div className="d-flex justify-content-evenly align-items-center">
                      <img
                        className="titos-transition"
                        src="./assets/images/titos/titos-old.jpeg"
                      />
                      <img
                        className="titos-transition-arrow"
                        src="/assets/images/arrow.png"
                      />
                      <img
                        className="titos-transition"
                        src="/assets/images/homepage-gallery/12.jpg"
                      />
                    </div>
                  </div>
                  <div className="faq__group" id="faq">
                    <h5 className="atr">Frequently asked questions</h5>
                    <div className="accordion" id="accordionExampleFund">
                      <div className="accordion-item content__space">
                        <h5 className="accordion-header" id="headingFundOne">
                          <span className="icon_box">
                            <img
                              src="/assets/images/icons/message.png"
                              alt="Fund"
                            />
                          </span>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFundOne"
                            aria-expanded="true"
                            aria-controls="collapseFundOne"
                          >
                            What is TITO’S PRIVILEGE CARD all about?
                          </button>
                        </h5>
                        <div
                          id="collapseFundOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingFundOne"
                          data-bs-parent="#accordionExampleFund"
                        >
                          <div className="accordion-body">
                            <p>
                              TITO’S PRIVILEGE CARD opens the door to a world of
                              exclusive benefits, including lifetime access to
                              our nightclubs, beverage discounts, and more. It's
                              your passport to unforgettable experiences.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item content__space">
                        <h5 className="accordion-header" id="headingFundTwo">
                          <span className="icon_box">
                            <img
                              src="/assets/images/icons/message.png"
                              alt="Fund"
                            />
                          </span>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFundTwo"
                            aria-expanded="false"
                            aria-controls="collapseFundTwo"
                          >
                            How can I purchase a TITO’S PRIVILEGE CARD?
                          </button>
                        </h5>
                        <div
                          id="collapseFundTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFundTwo"
                          data-bs-parent="#accordionExampleFund"
                        >
                          <div className="accordion-body">
                            <p>
                              Buying TITO’S PRIVILEGE CARD is easy. Simply visit
                              our TITO’S PRIVILEGE CARD page, and Buy . You'll
                              be on your way to enjoying all the perks.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item content__space">
                        <h5 className="accordion-header" id="headingFundThree">
                          <span className="icon_box">
                            <img
                              src="/assets/images/icons/message.png"
                              alt="Fund"
                            />
                          </span>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFundThree"
                            aria-expanded="false"
                            aria-controls="collapseFundThree"
                          >
                            Can I earn commissions as a TITOS member?
                          </button>
                        </h5>
                        <div
                          id="collapseFundThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFundThree"
                          data-bs-parent="#accordionExampleFund"
                        >
                          <div className="accordion-body">
                            <p>
                              Absolutely! TITOS offers a referral and affiliate
                              program for social media influencers. You can earn
                              commissions for each successful sale you generate
                              and provide special discounts to your followers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-lg-2" id="coupon">
                <div className="p__details__sidebar">
                  <div className="coupon-card">
                    <img
                      src="./assets/images/coupon-cards/card-bg.svg"
                      alt="..."
                      style={{ width: "100%" }}
                    />
                    <span className="coupon-card-title">
                      Exclusive Access to TITOS Goa Events
                    </span>
                    <span className="coupon-card-content">
                      Offer: Prepare Yourself For
                      <ul className="mt-3 p-0">
                        <li className="text-white">
                          <img
                            src="./assets/images/coupon-cards/star-red.svg"
                            alt="..."
                          />
                          Lifetime Access to Premier Nightlife
                        </li>
                        <li className="text-white">
                          <img
                            src="./assets/images/coupon-cards/star-red.svg"
                            alt="..."
                          />
                          25% Discount on All Drinks
                        </li>
                      </ul>
                    </span>
                    <span className="coupon-card-bottom">
                      <button
                        className="button button--effect w-100"
                        onClick={investNow}
                      >
                        Buy Now
                      </button>
                      {/* <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <spna>Total Used</spna>
                          <spna className="coupon-card-bottom-txt">
                            03 times
                          </spna>
                        </div>
                        <div className="d-flex flex-column">
                          <spna>Last Used</spna>
                          <spna className="coupon-card-bottom-txt">
                            1 Feb, 2024
                          </spna>
                        </div>
                        <div className="d-flex flex-column">
                          <spna>Valid Till</spna>
                          <spna className="coupon-card-bottom-txt">
                            1 Feb, 2030
                          </spna>
                        </div>
                      </div> */}
                    </span>
                    <div className="coupon-card-qrcode">
                      <QRCode
                        value="https://titos.global/"
                        bgColor="#000000"
                        fgColor="#ffffff"
                        ecLevel="H"
                        logoImage="./assets/images/titos-white.png"
                        logoWidth={80}
                        logoHeight={40}
                        removeQrCodeBehindLogo={true}
                        qrStyle="dots"
                        eyeRadius={8}
                      />
                    </div>
                  </div>
                  <div className="group brini">
                    <h5 className="neutral-top">
                      TITO’S PRIVILEGE CARD Benefits
                    </h5>
                    <hr />
                    <ol>
                      <li className="mb-2">
                        <b>Affordable Access:</b> Avail Tito's Card at at INR
                        10,000 with a 50% discount for a limited time, with
                        lifetime free entry to Tito's clubs.
                      </li>
                      <li className="mb-2">
                        <b>Exclusive Discounts:</b> Enjoy 25% off on beverages
                        and 15% off on food at Tito's clubs.
                      </li>
                      <li>
                        <b>Diverse Partner Discounts:</b> Access varied
                        discounts at partner establishments, including hotels,
                        clubs, restaurants, spas, cafes, shopping outlets,
                        boutiques, water sports, tours, and more.
                      </li>
                      <li className="mb-2">
                        <b>Global Brand:</b> Associate with Tito's, a renowned
                        brand with a 50-year legacy in Goa.
                      </li>
                      <li className="mb-2">
                        <b>Trusted Brand:</b> Customers proudly enjoy the Tito's
                        Privilege Card, a trusted brand.
                      </li>
                      <li className="mb-2">
                        <b>Lifetime Value:</b> By offering lifetime benefits and
                        continuous engagement.
                      </li>
                    </ol>
                  </div>
                  <div className="group birinit">
                    <h6>Share via Social </h6>
                    <div className="social text-start">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div className="group alt__brin">
                    <h5>
                      Key Updates <i className="fa-solid fa-bell"></i>
                    </h5>
                    <hr />
                    <div className="singl__wrapper">
                      <div className="singl">
                        <img src="/assets/images/check.png" alt="Check" />
                        <div>
                          <p>1 Mar, 2024</p>
                          <a>Upcoming Event 1</a>
                        </div>
                      </div>
                      <div className="singl">
                        <img src="/assets/images/check.png" alt="Check" />
                        <div>
                          <p>1 Apr, 2024</p>
                          <a>Upcoming Event 2</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group alt__brin__last">
                    <h5>RESOURCES</h5>
                    <hr />
                    <h6>Tito's Privilege Card Guide</h6>
                    <p>
                      A comprehensive guide to your <b>TITO’S PRIVILEGE CARD</b>{" "}
                      benefits and privileges.
                    </p>
                    <a
                      href
                      className="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(propertyDetails?.documents[0]);
                      }}
                    >
                      Get Tito's Privilege Card Guide{" "}
                      <i className="fa-solid fa-download"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #details section end ==== --> */}

      {/* <!-- ==== property gallery two section start ==== --> */}
      <section className="p__gallery wow fadeInUp" id="gallery">
        <div className="container">
          <hr className="divider" />
          <div className="p__gallery__area section__space">
            <div className="title__with__cta">
              <div className="row d-flex align-items-center">
                <div className="col-lg-8">
                  <h2>Gallery</h2>
                </div>
              </div>
            </div>
            <div className="row p__gallery__single">
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/1.jpg">
                  <img
                    src="/assets/images/homepage-gallery/1.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/2.jpg">
                  <img
                    src="/assets/images/homepage-gallery/2.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/3.jpg">
                  <img
                    src="/assets/images/homepage-gallery/3.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/4.jpg">
                  <img
                    src="/assets/images/homepage-gallery/4.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/5.jpg">
                  <img
                    src="/assets/images/homepage-gallery/5.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              {/* <div className="col-md-6 col-lg-4 gallery__single__two" >
                <a href='/assets/images/homepage-gallery/6.jpg'>
                  <img src='/assets/images/homepage-gallery/6.jpg' alt="Property Image" />
                </a>
              </div> */}
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/7.jpg">
                  <img
                    src="/assets/images/homepage-gallery/7.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/8.jpg">
                  <img
                    src="/assets/images/homepage-gallery/8.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/9.jpg">
                  <img
                    src="/assets/images/homepage-gallery/9.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/10.jpg">
                  <img
                    src="/assets/images/homepage-gallery/10.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/11.jpg">
                  <img
                    src="/assets/images/homepage-gallery/11.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/12.jpg">
                  <img
                    src="/assets/images/homepage-gallery/12.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/13.jpg">
                  <img
                    src="/assets/images/homepage-gallery/13.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/14.jpg">
                  <img
                    src="/assets/images/homepage-gallery/14.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/15.jpg">
                  <img
                    src="/assets/images/homepage-gallery/15.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/16.jpg">
                  <img
                    src="/assets/images/homepage-gallery/16.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/17.jpg">
                  <img
                    src="/assets/images/homepage-gallery/17.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/18.jpg">
                  <img
                    src="/assets/images/homepage-gallery/18.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
              <div className="col-md-6 col-lg-4 gallery__single__two">
                <a href="/assets/images/homepage-gallery/19.jpg">
                  <img
                    src="/assets/images/homepage-gallery/19.jpg"
                    alt="Property Image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== property gallery two section end ==== --> */}

      {/* <!-- ==== market section start ==== --> */}
      <section className="market section__space over__hi">
        <div className="container">
          <div className="market__area">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-xl-5">
                <div className="market__thumb thumb__rtl column__space">
                  <img
                    src="/assets/images/market-two-illustration.svg"
                    alt="Explore the Market"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 offset-xl-1">
                <div className="content">
                  <h5 className="neutral-top">
                    DISCOVER ENDLESS POSSIBILITIES
                  </h5>
                  <h2>Elevate Your Lifestyle with TITO’S PRIVILEGE CARD</h2>
                  <p>
                    Elevate Your Lifestyle with TITO’S PRIVILEGE CARD.
                    <br />
                    Experience a World of Opportunities Through TITO’S PRIVILEGE
                    CARD. Join Us and Elevate Your Lifestyle.
                  </p>
                  <a href="#coupon" className="button button--effect">
                    Buy Now
                  </a>
                  <img src="/assets/images/arrow.png" alt="Go to" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #market section end ==== --> */}

      {/* <!-- ==== footer section start ==== --> */}
      <footer
        className="footer pos__rel over__hi"
        data-background="./assets/images/background-footer.jpg"
      >
        <div className="container">
          <div className="footer__newsletter">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="footer__newsletter__content column__space">
                  <h3>Subscribe for updates</h3>
                  <p>
                    Stay on top of the latest blog posts, news and announcements
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-xxl-5 offset-xxl-1">
                <div className="footer__newsletter__form">
                  <form action="#" method="post">
                    <div className="footer__newsletter__input__group">
                      <div className="input">
                        <input
                          type="email"
                          name="news__letter"
                          id="newsLetterMail"
                          placeholder="Enter Your Email"
                          required="required"
                        />
                      </div>
                      <button type="submit" className="button button--effect">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__area section__space">
            <div className="row">
              <div className="col-md-12 col-lg-5 col-xl-5">
                <div className="footer__intro">
                  <a href="#">
                    <img
                      src="/assets/images/titos-white.png"
                      alt="Titos-logo"
                      className="logo"
                    />
                  </a>
                  <p>
                    Tito's is one of the most popular nightclubs in Goa, located
                    in the beach town of Baga. It has been a popular party spot
                    for both locals and tourists for several decades. Tito's is
                    known for its energetic atmosphere, lively music, and
                    diverse crowd. The club has several dance floors, bars, and
                    a pool area.
                  </p>
                  <div className="social">
                    <a href="/">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-1 col-lg-1 col-xl-1" />
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Company</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="/#aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/#team">Our Team</a>
                    </li>
                    <li>
                      <a href="/#whatwedo">What we do?</a>
                    </li>
                    <li>
                      <a href="/#location">Our Locations</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="/#servies">Services We Offer</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Loyalty Program</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="#coupon">Buy Now</a>
                    </li>
                    <li>
                      <a href="#howitworks">How it works</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="#faq">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links">
                  <h5>Legal</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="javascript:void(0)">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Term & Conditions</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Cookie Policy</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="javascript:void(0)">Key Risks</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__credit">
            <div className="row d-flex align-items-center">
              <div className="col-sm-9 order-1 order-sm-0 w-100">
                <div className="footer__copyright">
                  <p className="text-center">Copyright &copy; Titos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ==== #footer section end ==== --> */}

      {/* <!-- Scroll To Top --> */}
      <a href="#" className="scrollToTop">
        <i className="fa-solid fa-angles-up"></i>
      </a>

      {/* Modal */}
      <Modal
        title="Select Payment Option"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={700}
      >
        <div className="d-flex justify-content-around">
          {/* <div className="payment-modal-img" onClick={stripePayment}>
            <img src="./assets/images/payment-gateway/Stripe-Logo.png" />
          </div> */}
          {/* <div className="payment-modal-img" onClick={stripePayment}>
            <img src="./assets/images/payment-gateway/ccavenue-Logo.png" />
          </div> */}
          <div className="payment-modal-img" onClick={handlePayment}>
            <img src="./assets/images/payment-gateway/razorpay.webp" />
          </div>
        </div>
      </Modal>

      <Modal
        // title="Select Payment Option"
        open={isModal2Open}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[]}
        width={700}
      >
        {stripePromise && clientSecret && (
          <Elements options={{ clientSecret }} stripe={stripePromise}>
            <CheckoutForm
              closeModal1={handleCancel}
              closeModal2={handleCancel2}
            />
          </Elements>
        )}
      </Modal>
    </>
  );
}

export default PropertyDetails;
