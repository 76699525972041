import React, { useEffect, useState } from "react";
import SideNavbar from "../components/SideNavbar";
import { Header } from "../components/Header";

const Transaction = () => {
  const [transaction, setTransaction] = useState();

  const getTransactions = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}main/user-investments`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await res.json();
    console.log(data.data);
    setTransaction(data?.data)
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if (transaction) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#transactionTable');`;
      document.body.appendChild(script);
    }
  }, [transaction]);

  return (
    <>
      <Header />

      {/* // <!-- ==== dashboard section start ==== --> */}
      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <SideNavbar />
              </div>
              <div class="col-xxl-9">
                <div class="main__content">
                  <div class="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" class="collapse__sidebar__btn">
                      <i class="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div class="main__content-dashboard">
                    <div class="breadcrumb-dashboard">
                      <h5>Transactions</h5>
                      <div>
                        <a href="/profile">Home</a>
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <a href="javascript:void(0)">Transactions</a>
                      </div>
                    </div>
                    <div class="custome-table">
                      <div class="intro mb-4">
                        <h5>Transactions</h5>
                        {/* <h6>Total Number of Transactions <span>({transaction?.length})</span></h6> */}
                      </div>
                      <table id="transactionTable" class="table table-striped">
                        <thead>
                          <tr>
                            <th>Project</th>
                            <th>Amount Invested</th>
                            <th>Investment Type</th>
                            <th>Date / Time</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: '360px', overflowY: 'scroll' }}>
                          {transaction?.map((trans) => {
                            return (
                              <tr>
                                <td>
                                  {/* <img src="assets/images/about-video-illustration.jpg" alt="Investment" className="table-titos-img" /> */}
                                  Titos
                                </td>
                                <td>₹{trans.total_amount}</td>
                                <td>{trans.investment_type}</td>
                                <td>{trans.created_at}</td>
                                <td>{trans.is_captured ? 'Success' : 'Pending'}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- ==== #dashboard section end ==== --> */}
    </>

  );
};

export default Transaction;