import React, { useState, useEffect } from "react";
import { Header } from "../components/Header";
import { toast } from "react-toastify";

const Exchange = () => {
  const token = localStorage.getItem("titos-token");

  if (!token) {
    window.location.href = "/login";
  }

  const [sellPrice, setSellPrice] = useState();
  const [sellQuantity, setSellQuantity] = useState();

  const [buyPrice, setBuyPrice] = useState();
  const [buyQuantity, setBuyQuantity] = useState();

  const clubId = "5b4edf9c-6eb0-47cc-9d8c-ea42f0e51e7a";

  const placeBuyOrder = async () => {
    if (buyQuantity > 0) {
      if (buyPrice > 0) {
        try {
          const token = localStorage.getItem("titos-token");
          const res = await fetch(
            `${process.env.REACT_APP_API_ROOT_URL}main/place-order`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                club_id: clubId,
                share_count: buyQuantity,
                per_share_price: buyPrice,
                order_type: "buy",
                is_exchange: true,
              }),
            }
          );
          const data = await res.json();
          console.log(data);
          if (data.status) {
            toast.success("Buy Order Placed Successfully");
            setTimeout(() => {
              window.location.href = "/transactions";
            }, 1500);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Please enter Buying Price");
      }
    } else {
      toast.error("Please enter Buy Quantity");
    }
  };

  const placeSellOrder = async () => {
    if (sellQuantity > 0) {
      if (sellPrice > 0) {
        try {
          const token = localStorage.getItem("titos-token");
          const res = await fetch(
            `${process.env.REACT_APP_API_ROOT_URL}main/place-order`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                club_id: clubId,
                share_count: sellQuantity,
                per_share_price: sellPrice,
                order_type: "sell",
                is_exchange: true,
              }),
            }
          );
          const data = await res.json();
          console.log(data);
          if (data.status) {
            toast.success("Sell Order Placed Successfully");
            setTimeout(() => {
              window.location.href = "/transactions";
            }, 1500);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Please enter Selling Price");
      }
    } else {
      toast.error("Please enter Sell Quantity");
    }
  };

  const [transaction, setTransaction] = useState();

  const getTransactions = async () => {
    const token = localStorage.getItem("titos-token");
    const res = await fetch(
      `${process.env.REACT_APP_API_ROOT_URL}main/user-investments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    console.log(data.data);
    setTransaction(data?.data);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const [purchasedShare, setPurchasedShare] = useState();

  const getPurchasedShare = () => {
    if (transaction) {
      const temp = transaction?.reduce((sum, ele) => sum + ele?.share_count, 0);
      setPurchasedShare(temp);
    }
  };

  useEffect(() => {
    getPurchasedShare();
  }, [transaction]);

  const changeShareCount = (fraction) => {
    setSellQuantity(Math.round(purchasedShare * fraction));
  };

  const [lastTradedPrice, setLastTradedPrice] = useState();

  const getLastTradedPrice = async () => {
    const token = localStorage.getItem("titos-token");
    const res = await fetch(
      `${process.env.REACT_APP_API_ROOT_URL}main/last-traded-price`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    console.log(data);
    setLastTradedPrice(data.data.value);
  };

  useEffect(() => {
    getLastTradedPrice();
  }, []);

  return (
    <>
      <Header />

      {/* ==== details section start ==== */}
      <div
        className="property__details__banner bg__img clear__top"
        data-background="/assets/images/banner/property-details-banner.jpg"
        style={{ height: "350px" }}
      />

      <section className="p__details p__details__two faq section__space__bottom">
        <div className="container">
          <div className="p__details__area">
            <div className="row">
              <div className="col-lg-7">
                <div className="p__details__content">
                  <div className="intro">
                    {/* <h3>
                      How much will I get if I <span>invest </span>
                    </h3> */}
                  </div>
                  <div className="investment__amount__area">
                    <div className="investment__wrapper">
                      <div className="investment__wrapper__inner">
                        <h5>Price</h5>
                        <div className="input input--secondary">
                          <input
                            type="number"
                            name="invest__amount"
                            id="investAmount"
                            placeholder="Enter price at which you want to sell"
                            required="required"
                            value={sellPrice}
                            onChange={(e) => setSellPrice(e.target.value)}
                          />
                        </div>
                        <h5>Shares</h5>
                        <div className="input input--secondary">
                          <input
                            type="number"
                            name="invest__amount"
                            id="investAmount"
                            placeholder="Enter quantity you want to sell"
                            required="required"
                            value={sellQuantity}
                            onChange={(e) => setSellQuantity(e.target.value)}
                          />
                        </div>
                        <div className="radio__group">
                          <div>
                            <input
                              type="radio"
                              id="pone"
                              defaultChecked=""
                              className="radio-input"
                              name="radio-group"
                              onClick={() => changeShareCount(0)}
                            />
                            <label htmlFor="pone" className="radio-label">
                              {" "}
                              <span className="radio-border" /> 0%{" "}
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="pone2"
                              defaultChecked=""
                              className="radio-input"
                              name="radio-group"
                              onClick={() => changeShareCount(0.25)}
                            />
                            <label htmlFor="pone2" className="radio-label">
                              {" "}
                              <span className="radio-border" /> 25%{" "}
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="ptwo"
                              defaultChecked=""
                              className="radio-input"
                              name="radio-group"
                              onClick={() => changeShareCount(0.5)}
                            />
                            <label htmlFor="ptwo" className="radio-label">
                              {" "}
                              <span className="radio-border" /> 50%
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="pthree"
                              defaultChecked=""
                              className="radio-input"
                              name="radio-group"
                              onClick={() => changeShareCount(0.75)}
                            />
                            <label htmlFor="pthree" className="radio-label">
                              {" "}
                              <span className="radio-border" /> 75%
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="pfour"
                              defaultChecked=""
                              className="radio-input"
                              name="radio-group"
                              onClick={() => changeShareCount(1)}
                            />
                            <label htmlFor="pfour" className="radio-label">
                              {" "}
                              <span className="radio-border" /> 100%{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="investment__footer">
                          <p class="secondary mb-0">Amount you will get</p>
                          <h4 class="neutral-bottom">
                            ₹{" "}
                            {isNaN(sellPrice * sellQuantity)
                              ? 0
                              : sellPrice * sellQuantity}
                          </h4>
                        </div>
                        <div className="investment__footer w-25">
                          <button
                            className="button button--effect w-100"
                            onClick={placeSellOrder}
                          >
                            Sell
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="intro mt-5">
                    {/* <h3>
                      How much will I get if I <span>invest</span>
                    </h3> */}
                  </div>
                  <div className="investment__amount__area">
                    <div className="investment__wrapper">
                      <div className="investment__wrapper__inner">
                        <h5>Price</h5>
                        <div className="input input--secondary">
                          <input
                            type="number"
                            name="invest__amount"
                            id="investAmount"
                            placeholder="Enter price at which you want to buy"
                            required="required"
                            value={buyPrice}
                            onChange={(e) => setBuyPrice(e.target.value)}
                          />
                        </div>
                        <h5>Shares</h5>
                        <div className="input input--secondary">
                          <input
                            type="number"
                            name="invest__amount"
                            id="investAmount"
                            placeholder="Enter quantity you want to buy"
                            required="required"
                            value={buyQuantity}
                            onChange={(e) => setBuyQuantity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="investment__footer">
                          <p class="secondary mb-0">Investment Amount</p>
                          <h4 class="neutral-bottom">
                            ₹{" "}
                            {isNaN(buyPrice * buyQuantity)
                              ? 0
                              : buyPrice * buyQuantity}
                          </h4>
                        </div>
                        <div className="investment__footer w-25">
                          <button
                            className="button button--effect w-100"
                            onClick={placeBuyOrder}
                          >
                            Buy
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="p__details__sidebar">
                  <div className="intro">
                    <div
                      className="countdown__wrapper"
                      style={{ border: "none", padding: "0" }}
                    >
                      <h5>
                        Last Traded Price: <span>{lastTradedPrice}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="group exchange-sidebar brin">
                    <div className="acus__content">
                      <div class="investment-table investment-table-two exchange-table">
                        <div class="intro">
                          <h5>Transaction History</h5>
                        </div>
                        <div class="table-wrapper orderbook-table">
                          <table>
                            <tr>
                              <th>Price</th>
                              <th>Order Type</th>
                              <th>Qty</th>
                              <th>Total</th>
                            </tr>
                            <div
                              style={{
                                maxHeight: "500px",
                                overflowY: "scroll",
                              }}
                            >
                              {transaction?.map((trans) => {
                                if (!trans?.is_exchange) return <></>;
                                return (
                                  <tr>
                                    <td>{trans?.price_per_share}</td>
                                    <td>{trans?.investment_type}</td>
                                    <td>{trans?.share_count}</td>
                                    <td>{trans?.total_amount}</td>
                                  </tr>
                                );
                              })}
                            </div>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== #details section end ==== */}

      {/* ==== footer section start ==== */}
      <footer
        className="footer pos__rel over__hi"
        data-background="./assets/images/background-footer.jpg"
      >
        <div className="container">
          <div className="footer__newsletter">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="footer__newsletter__content column__space">
                  <h3>Subscribe for updates</h3>
                  <p>
                    Stay on top of the latest blog posts, news and announcements
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-xxl-5 offset-xxl-1">
                <div className="footer__newsletter__form">
                  <form action="#" method="post">
                    <div className="footer__newsletter__input__group">
                      <div className="input">
                        <input
                          type="email"
                          name="news__letter"
                          id="newsLetterMail"
                          placeholder="Enter Your Email"
                          required="required"
                        />
                      </div>
                      <button type="submit" className="button button--effect">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__area section__space">
            <div className="row">
              <div className="col-md-12 col-lg-5 col-xl-5">
                <div className="footer__intro">
                  <a href="index.html">
                    <img
                      src="/assets/images/titos-white.png"
                      alt="Titos-logo"
                      className="logo"
                    />
                  </a>
                  <p>
                    Tito's is one of the most popular nightclubs in Goa, located
                    in the beach town of Baga. It has been a popular party spot
                    for both locals and tourists for several decades. Tito's is
                    known for its energetic atmosphere, lively music, and
                    diverse crowd. The club has several dance floors, bars, and
                    a pool area.
                  </p>
                  <div className="social">
                    <a href="/">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-1 col-lg-1 col-xl-1" />
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Company</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="/#aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/#team">Our Team</a>
                    </li>
                    <li>
                      <a href="/#whatwedo">What we do?</a>
                    </li>
                    <li>
                      <a href="/#location">Our Locations</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="/#servies">Services We Offer</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Invest</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="/loyalty-program">Invest Now</a>
                    </li>
                    <li>
                      <a href="/loyalty-program#howitworks">How it works</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="/loyalty-program#faq">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links">
                  <h5>Legal</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="javascript:void(0)">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Term & Conditions</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Cookie Policy</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="javascript:void(0)">Key Risks</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__credit">
            <div className="row d-flex align-items-center">
              <div className="col-sm-9 order-1 order-sm-0 w-100">
                <div className="footer__copyright">
                  <p className="text-center">Copyright &copy; Titos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ==== #footer section end ==== */}

      {/* Scroll To Top */}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fa-solid fa-angles-up" />
      </a>
    </>
  );
};

export default Exchange;
