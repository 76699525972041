import React, { useEffect, useState } from "react";
import SideNavbar from "../components/SideNavbar";
import { Header } from "../components/Header";
import { DatePicker } from 'antd';
import moment from "moment";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import copy from "copy-to-clipboard";

const Account = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [dob, setDob] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [gender, setGender] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [anniversary, setAaniversary] = useState();
  const [mail, setMail] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [sponsor, setSponsor] = useState();

  const [giftBd, setGiftBd] = useState();
  const [giftAnni, setGiftAnni] = useState();

  const [editBilling, setEditBilling] = useState();
  const [balance, setBalance] = useState();
  const [accountName, setAccountName] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [accountNumber, setAccountNumber] = useState();

  const [referredUser, setReferredUser] = useState();

  const getProfile = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/get-profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await res.json();
    console.log(data)
    setFirstName(data.data.first_name);
    setLastName(data.data.last_name);
    setGender(data.data.gender);
    setMobile(data.data.phone);
    setMail(data.data.email);
    setAddress(data.data.address);
    setAaniversary(data.data.anniversary_date);
    setDob(data.data.date_of_birth);
    setMaritalStatus(data.data.marital_status);
    setPincode(data.data.pincode);
    setState(data.data.state);
    setCountry(data.data.country);
    setAccountName(data.data.account_holder_name);
    setAccountNumber(data.data.account_number);
    setIfscCode(data.data.ifcs_code);
    setSponsor(data.data.refer_user);
    setBalance(data.data.wallet_balance);
    setReferredUser(data.data.refered_users);
  };

  const getVoucher = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/user-vouchers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await res.json();
    console.log(data)
    if (data.data) {
      data.data.forEach((voucher) => {
        voucher.type === "birthday" && setGiftBd(voucher.code);
        voucher.type === "anniversary" && setGiftAnni(voucher.code);
      })
    }
  };

  useEffect(() => {
    getProfile();
    getVoucher();
  }, []);

  const updateUserDetails = async () => {
    try {
      const token = localStorage.getItem('titos-token');
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/user-onboarding`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'first_name': firstName,
          'last_name': lastName,
          'date_of_birth': dob,
          'anniversary_date': anniversary,
          'gender': gender,
          'address': address,
          'marital_status': maritalStatus,
          'phone': mobile,
          'state': state,
          'pincode': pincode,
          'country': country,
          'state': state
        })
      });
      const data = await res.json();
      console.log(data)
      if (data.status) {
        toast.success('Profile Updated Successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error('Something went wrong!');
      console.log(error);
    }
  };

  const changeMaritalStatus = (e) => {
    setMaritalStatus(e.target.value);
    if (maritalStatus === 'unmarried') {
      setAaniversary(null);
    }
  };

  const changeGender = (e) => {
    setGender(e.target.value);
  };

  const countryList = Country.getAllCountries();

  const [stateList, setStateList] = useState(State.getAllStates());

  useEffect(() => {
    if (editProfile) {
      countryList.forEach((country) => {
        const option = document.createElement("option");
        option.text = country.name;
        option.value = country.isoCode;
        const select = document.getElementById("country");
        select.appendChild(option);
      });
    }
  }, [editProfile]);

  useEffect(() => {
    setStateList(State.getStatesOfCountry(country));
  }, [country, editProfile]);

  useEffect(() => {
    if (editProfile) {
      const select = document.getElementById("state");

      select.innerHTML = "";

      stateList.forEach((innerState) => {
        const option = document.createElement("option");
        option.text = innerState.name;
        option.value = innerState.name;
        option.selected = state === innerState.name;
        select.appendChild(option);
      });
    }
  }, [stateList, editProfile]);

  const changeCountry = (e) => {
    setCountry(e.target.value);
    console.log(e.target.value);
  };

  const changeState = (e) => {
    setState(e.target.value);
    console.log(e.target.value)
  };

  const updateBankDetails = async () => {
    try {
      const token = localStorage.getItem('titos-token');
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/user-onboarding`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'account_holder_name': accountName, 'account_number': accountNumber, 'ifcs_code': ifscCode })
      });
      const data = await res.json();
      console.log(data)
      if (data.status) {
        toast.success('Bank Details Updated Successfully');
      }
    } catch (error) {
      toast.error('Something went wrong!');
      console.log(error);
    }
  };

  const copyReferralLink = () => {
    document.getElementsByClassName('copy')[0].classList.add('active');
    setTimeout(() => {
      document.getElementsByClassName('copy')[0].classList.remove('active');
    }, 2000);
    copy(`https://${window.location.host}/register?referId=${mail}`);
  };

  useEffect(() => {
    if (referredUser) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#referandearnTable');`;
      document.body.appendChild(script);
    }
  }, [referredUser]);

  return (
    <>
      <Header />

      {/* <!-- ==== dashboard section start ==== --> */}
      <div className="dashboard section__space__bottom">
        <div className="container">
          <div className="dashboard__area">
            <div className="row">
              <div className="col-xxl-3">
                <SideNavbar />
              </div>
              <div className="col-xxl-9">
                <div className="main__content">
                  <div className="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" className="collapse__sidebar__btn">
                      <i className="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div className="main__content-dashboard">
                    <div className="breadcrumb-dashboard">
                      <h5>Account</h5>
                      <div>
                        <a href="/profile">Home</a>
                        <i className="fa-solid fa-arrow-right-long"></i>
                        <a href="javascript:void(0)">Account</a>
                      </div>
                    </div>
                    <div className="account-info">
                      <div className="account-info__btn-wrapper">
                        <a href="#general" className="account-info__btn account-info__btn-active button button--effect button--secondary">General</a>
                        <a href="#giftcard" className="account-info__btn button button--effect button--secondary">Gift Card</a>
                        <a href="#billing" className="account-info__btn button button--effect button--secondary">Billing Details</a>
                        <a href="#referandearn" className="account-info__btn button button--effect button--secondary">Refer and Earn</a>
                      </div>
                      <div className="account-content_wrapper">
                        <div className="account-content" id="general">
                          <div className="avatar-wrapper">
                            <div className="avatar-content">
                              <div className="avatar">
                                <img src="assets/images/team/ryan.png" alt="Avatar" />
                              </div>
                              <div className="avatar-content__guideline">
                                <p style={{ margin: '0' }}>
                                  <b>
                                    {firstName || lastName
                                      ? firstName + ' ' + lastName
                                      : ''
                                    }
                                  </b>
                                </p>
                                <p style={{ margin: '0' }}>{mail ? mail : ''}</p>
                                <p style={{ margin: '0' }}>{sponsor ? 'Referred by: ' + sponsor : ''}</p>
                                {/* <p style={{ margin: '0' }}>{dayjs(dob, 'YYYY-MM-DD') ? dayjs(dob, 'YYYY-MM-DD') : ''}</p> */}
                              </div>
                            </div>
                            <a
                              href="#profile"
                              className="button button--secondary button--effect"
                              onClick={() => setEditProfile(!editProfile)}
                            >
                              Edit Profile
                            </a>
                          </div>
                          {editProfile
                            ? <>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="saveFirstName">First Name</label>
                                    <input type="text" name="save__first__name" value={firstName}
                                      id="saveFirstName" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)}
                                      required="required" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="saveLastName">Last Name</label>
                                    <input type="text" name="save__last__name" id="saveLastName" value={lastName}
                                      placeholder="Last Name" required="required" onChange={(e) => setLastName(e.target.value)} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="saveEmail">Email</label>
                                    <input
                                      type="email"
                                      name="save_email"
                                      id="saveEmail"
                                      value={mail}
                                      placeholder="Enter Your Email"
                                      required="required"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="savePhone">Phone</label>
                                    <input type="number" name="save_phone" id="savePhone" value={mobile}
                                      placeholder="345-323-1234" required="required"
                                      onChange={(e) => setMobile(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="input input--secondary">
                                    <label>Gender</label>
                                    <select onChange={changeGender} className="nice-select">
                                      <option value="male" selected={gender === 'male'} >Male</option>
                                      <option value="female" selected={gender === 'female'} >Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="input input--secondary d-flex flex-column">
                                    <label>Date of birth</label>
                                    <DatePicker
                                      value={dob ? dayjs(dob, 'YYYY-MM-DD') : null}
                                      onChange={(dateString) => setDob(dayjs(dateString).format('YYYY-MM-DD'))}
                                      style={{ outline: '0', background: '#fff8f8', height: '65px', borderRadius: '10px', border: '1px solid #e4c3c3' }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="input input--secondary" style={{ background: '#fff8f8 !important' }}>
                                    <label>Marital status</label>
                                    <select onChange={changeMaritalStatus} className="nice-select" style={{ background: '#fff8f8 !important' }}>
                                      <option
                                        value="married"
                                        selected={maritalStatus === 'married'}
                                        style={{ background: '#fff8f8 !important' }}
                                      >
                                        Married
                                      </option>
                                      <option
                                        value="unmarried"
                                        selected={maritalStatus === 'unmarried'}
                                        style={{ background: '#fff8f8 !important' }}
                                      >
                                        Unmarried
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="input input--secondary d-flex flex-column">
                                    <label htmlFor="savePhone">Marriage Anniversary</label>
                                    {maritalStatus === 'unmarried'
                                      ? <DatePicker
                                        value={anniversary ? dayjs(anniversary, 'YYYY-MM-DD') : null}
                                        disabled
                                        style={{ outline: '0', background: '#fff8f8', height: '65px', borderRadius: '10px', border: '1px solid #e4c3c3' }}
                                      />
                                      : <DatePicker
                                        value={anniversary ? dayjs(anniversary, 'YYYY-MM-DD') : null}
                                        onChange={(dateString) => setAaniversary(dayjs(dateString).format('YYYY-MM-DD'))}
                                        style={{ outline: '0', background: '#fff8f8', height: '65px', borderRadius: '10px', border: '1px solid #e4c3c3' }}
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="input input--secondary">
                                    <label htmlFor="saveEmail">Address</label>
                                    <input type="text" name="save_email" id="saveEmail" value={address}
                                      onChange={(e) => setAddress(e.target.value)}
                                      placeholder="Enter Your Address" required="required" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-4">
                                  <div className="input input--secondary">
                                    <label>Country</label>
                                    <select value={country} onChange={changeCountry} className="nice-select" id='country'>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="input input--secondary">
                                    <label>State</label>
                                    <select value={state} onChange={changeState} className="nice-select" id='state' />
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="input input--secondary">
                                    <label>Pincode</label>
                                    <input type="number" name="save_phone" id="savePhone" value={pincode}
                                      onChange={(e) => setPincode(e.target.value)}
                                      placeholder="Enter pincode" required="required" />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button className="button button--effect" onClick={updateUserDetails}>
                                  Save Changes
                                </button>
                              </div>
                            </>
                            : <></>
                          }
                        </div>

                        <div className="account-content" id="giftcard">
                          <div className="account-content-single">
                            <div className="intro">
                              <h5>Gift Cards</h5>
                            </div>
                            <div className="investment-sidebar row mt-4">
                              {giftBd || giftAnni
                                ? <>
                                  {giftBd && <div className="statistics col-lg-5 ms-5">
                                    <h5>Birthday Gift</h5>
                                    <hr />
                                    <div className="group">
                                      <div>
                                        <h4>code: {giftBd}</h4>
                                        <p>Reedem your Coupon</p>
                                      </div>
                                    </div>
                                  </div>}
                                  {giftAnni && <div className="explore col-lg-5 ms-5">
                                    <div className="group">
                                      <h5>Annivercery Gift</h5>
                                      <hr />
                                      <div className="group">
                                        <div>
                                          <h4>code: {giftAnni}</h4>
                                          <p>Reedem your Coupon</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>}
                                </>
                                : <>Please complete profile to get Gift card</>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="account-content" id="billing">
                          <div className="avatar-wrapper">
                            <div className="avatar-content">
                              <div className="avatar">
                                <img src="assets/images/team/ryan.png" alt="Avatar" />
                              </div>
                              <div className="avatar-content__guideline">
                                <p style={{ margin: '0' }}>
                                  {accountName ? (
                                    <span>
                                      <strong>Account Holder Name:</strong> {accountName}
                                    </span>
                                  ) : ''}
                                </p>
                                <p style={{ margin: '0' }}>
                                  {accountNumber ? (
                                    <span>
                                      <strong>Account Holder Name:</strong> {accountNumber}
                                    </span>
                                  ) : ''}
                                </p>
                                <p style={{ margin: '0' }}>
                                  {ifscCode ? (
                                    <span>
                                      <strong>Account Holder Name:</strong> {ifscCode}
                                    </span>
                                  ) : ''}
                                </p>
                                <p style={{ margin: '0' }}><strong>Balance:</strong> {balance}</p>
                              </div>
                            </div>
                            <a
                              href="#profile"
                              className="button button--secondary button--effect"
                              onClick={() => setEditBilling(!editBilling)}
                            >
                              Update Billing details
                            </a>
                          </div>

                          {editBilling
                            ? <div className="investment-sidebar row mt-4">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="accountName">Account Holder Name</label>
                                    <input type="text" name="save__first__name" value={accountName}
                                      id="accountName" placeholder="Account Holder Name" onChange={(e) => setAccountName(e.target.value)}
                                      required="required" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input input--secondary">
                                    <label htmlFor="ifscCode">IFSC Code</label>
                                    <input type="text" name="save__last__name" id="ifscCode" value={ifscCode}
                                      placeholder="IFSC Code" required="required" onChange={(e) => setIfscCode(e.target.value)} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="input input--secondary">
                                    <label htmlFor="accountNumber">Account Number</label>
                                    <input type="text" name="save_email" id="accountNumber" value={accountNumber}
                                      onChange={(e) => setAccountNumber(e.target.value)}
                                      placeholder="Account Number" required="required" />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button className="button button--effect" onClick={updateBankDetails}>
                                  Update Account Details
                                </button>
                              </div>
                            </div>
                            : <></>
                          }
                        </div>

                        <div className="account-content" id="referandearn">
                          <div className="investment-sidebar row mt-4">
                            <section className="market market__two section__space over__hi py-3">
                              <div className="container">
                                <div className="market__area">
                                  <div className="row d-flex align-items-center">
                                    <div className="col-lg-6 col-xl-5">
                                      <div className="market__thumb thumb__rtl column__space d-none d-lg-block">
                                        <img
                                          src="assets/images/affiliate-illustration.svg"
                                          alt="Affiliate Program"
                                          style={{ width: '300px' }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 offset-xl-1">
                                      <div className="content">
                                        <h5 className="neutral-top">Earn Money</h5>
                                        {/* <h2>Affiliate Program</h2> */}
                                        <p>
                                          Earn commission for every new Titos customer you refer.
                                          Join our refer and earn program and get 500 ₹ for every referral.
                                        </p>
                                        <div style={{ position: 'relative' }}>
                                          <div className='copy'>
                                            <a
                                              href="javascript:void(0)"
                                              className="button button--effect copy"
                                              onClick={copyReferralLink}
                                            >
                                              Copy your Referral link
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <div class="custome-table">
                              <table className="table table-striped" id="referandearnTable">
                                <thead>
                                  <tr>
                                    <th>Email Id</th>
                                    <th>Account created at</th>
                                    <th>Referral Money</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {referredUser?.map((user) => {
                                    return (
                                      <tr>
                                        <td>{user?.email}</td>
                                        <td>{user?.created_at}</td>
                                        <td>{user?.transaction_done ? '500 ₹' : '0 ₹'}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ==== #dashboard section end ==== --> */}
    </>
  );
};

export default Account;