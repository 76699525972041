import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AdminHeader from "../components/AdminHeader";

const AdminLogin = () => {
  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("titos-admin");
      if (userDetails) {
        window.location.href = '/admin/profile';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [errors, setErrors] = useState({});
  const [isOTPEmailSent, setOTPEmailSent] = useState(false);

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleInputOTP = (event) => {
    setOTP(event.target.value);
  };

  const getOTP = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email }),
      });
      const data = await res.json();
      console.log(data);
      if (data.status) {
        setOTPEmailSent(true);
        toast.success("OTP sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(data.message);
      }
      setErrors({ ...errors, email: null });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.code === 400) {
        setErrors({
          ...errors,
          email: "Entered email is invalid. Please check.",
        });
      } else {
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const submitOTP = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/confirm-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email, 'email_otp': otp }),
      });
      const data = await res.json();
      console.log(data);
      if (data.status) {
        const user = data.data.user
        console.log(user);
        localStorage.setItem("titos-admin", JSON.stringify(user));
        localStorage.setItem('titos-admin-token', data.data.access);
        setTimeout(() => {
          window.location.href = '/admin/profile';
        }, 1000);
      } else {
        toast.error(
          data?.message || "Please try after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message || "Please try after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div
      className="wrapper bg__img d-flex justify-content-around"
      data-background='../assets/images/registration-bg.jpg'
      style={{ 'backgroundImage': 'url(../assets/images/registration-bg.jpg)' }}
    >
      <AdminHeader />

      <div className="registration_animation">
        <img src="../assets/images/enter-otp-animate.svg" />
      </div>
      <section className="registration clear__top">
        <div className="container">
          <div className="registration__area">
            <h4 className="neutral-top">Log in</h4>
            <div className="input input--secondary">
              <label htmlFor="loginMail">Email*</label>
              <input
                type="email"
                name="login__email"
                id="loginMail"
                placeholder="Enter your email"
                required
                onChange={handleInputEmail}
              />
            </div>
            {isOTPEmailSent ? (
              <div className="input input--secondary">
                <label htmlFor="loginPass">OTP*</label>
                <input
                  type="otp"
                  name="login__pass"
                  id="loginPass"
                  placeholder="OTP"
                  required
                  onChange={handleInputOTP}
                />
              </div>
            ) : null}
            <div className="checkbox login__checkbox">
              <label>
                <input
                  type="checkbox"
                  id="remeberPass"
                  name="remeber__pass"
                  value="remember"
                />
                <span className="checkmark"></span>
                Remember Me
              </label>
            </div>
            <div className="input__button">
              {isOTPEmailSent ? (
                <button
                  type="button"
                  className="button button--effect"
                  onClick={submitOTP}
                >
                  Login
                </button>
              ) : (
                <button
                  type="button"
                  className="button button--effect"
                  onClick={getOTP}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminLogin;