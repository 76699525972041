import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../app/mainSlice/mainSlice";

export const Header = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.main.user);

  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("titos-user");
      if (userDetails) {
        dispatch(setUser(JSON.parse(userDetails)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("titos-user");
      localStorage.removeItem("titos-token");
      dispatch(setUser(null));
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <a href="/" className="navbar-brand">
              <img
                src="/assets/images/titos-black.png"
                alt="Logo"
                className="logo"
              />
            </a>
            <div className="navbar__out order-2 order-xl-3">
              {user ? (
                <>
                  <ul className="navbar-nav flex-row mobile-navbar-menu">
                    <li className="nav-item dropdown">
                      <a href="/" style={{ color: "gray" }}>
                        Home
                      </a>
                    </li>
                    <li className="nav-item dropdown ms-2">
                      <a
                        href="/loyalty-program"
                        className="invest-now"
                        style={{ color: "gray" }}
                      >
                        Loyalty Program
                      </a>
                    </li>
                    {/* <li className="nav-item dropdown">
                  <a href="/exchange" className="invest-now" style={{ color: 'gray' }}>Exchange</a>
                </li> */}
                  </ul>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href = "/profile";
                    }}
                  >
                    <img
                      src="/assets/images/icons/profile.png"
                      style={{ width: "36px" }}
                    />
                  </span>
                  {/* <button onClick={signOut} className="button text-white">
                    Logout
                  </button> */}
                </>
              ) : (
                <div className="nav__group__btn">
                  <ul className="navbar-nav flex-row mobile-navbar-menu">
                    <li className="nav-item dropdown">
                      <a href="/" style={{ color: "gray" }}>
                        Home
                      </a>
                    </li>
                    <li className="nav-item dropdown ms-2">
                      <a
                        href="/loyalty-program"
                        className="invest-now"
                        style={{ color: "gray" }}
                      >
                        Loyalty Program
                      </a>
                    </li>
                    {/* <li className="nav-item dropdown">
                  <a href="/exchange" className="invest-now" style={{ color: 'gray' }}>Exchange</a>
                </li> */}
                  </ul>
                  <button
                    className="button button--effect text-white mobile-navbar-login"
                    onClick={() => (window.location.href = "/login")}
                  >
                    Login <i className="fa-solid fa-arrow-right-long"></i>
                  </button>
                </div>
              )}
              {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#primaryNav"
                aria-controls="primaryNav"
                aria-expanded="false"
                aria-label="Toggle Primary Nav"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button> */}
            </div>
            <div
              className="collapse navbar-collapse order-3 order-xl-2"
              id="primaryNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a href="/" style={{ color: "gray" }}>
                    Home
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    href="/loyalty-program"
                    className="invest-now"
                    style={{ color: "gray" }}
                  >
                    Loyalty Program
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a href="/exchange" className="invest-now" style={{ color: 'gray' }}>Exchange</a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
