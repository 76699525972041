import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3/dist/web3.min.js';
import { BrowserRouter } from 'react-router-dom';
import store from './app/redux/store';
import { Provider } from 'react-redux';

const getLibrary = (provider) => {
  return new Web3(provider);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Web3ReactProvider>
);