import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    user: null,
    sidebar: 'profile',
    admin: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },

    setSidebar: (state, action) => {
      state.sidebar = action.payload
    },

    setAdmin: (state, action) => {
      state.admin = action.payload
    },
  }
});

export const {
  setSidebar,
  setUser,
  setAdmin
} = mainSlice.actions

export default mainSlice.reducer