import React, { useEffect, useState } from "react";
import SideNavbar from "../components/SideNavbar";
import { Header } from "../components/Header";
import { toast } from "react-toastify";

const Withdraw = () => {
  const [balance, setBalance] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState();

  const getProfile = async () => {
    const token = localStorage.getItem('titos-token');
    const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/get-profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await res.json();
    setBalance(data.data.wallet_balance);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const withdraw = async () => {
    if (withdrawAmount <= balance) {
      const token = localStorage.getItem('titos-token');
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/withdraw-request`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'amount': parseFloat(withdrawAmount) })
      });
      const data = await res.json();
      console.log(data);
      toast.success('Withdraw request placed successfully');
      setTimeout(() => {
        window.location.href = '/withdraw';
      }, 2000);
    } else {
      toast.error('Insufficient balance');
    }
  };

  return (
    <>
      <Header />

      {/* // <!-- ==== dashboard section start ==== --> */}
      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <SideNavbar />
              </div>
              <div class="col-xxl-9">
                <div class="main__content">
                  <div class="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a href="javascript:void(0)" class="collapse__sidebar__btn">
                      <i class="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div class="main__content-dashboard">
                    <div class="breadcrumb-dashboard">
                      <h5>Withdraw</h5>
                      <div>
                        <a href="/profile">Home</a>
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <a href="javascript:void(0)">Withdraw</a>
                      </div>
                    </div>
                    <div class="withdraw-funds">
                      <div class="withdraw-funds__inner">
                        <h5>Withdraw Funds</h5>
                        <p>Balance - <b>{balance}</b></p>
                        <div class="input input--secondary">
                          <label for="withdrawAmount">Amount</label>
                          <input
                            type="number"
                            name="withdraw_amount"
                            id="withdrawAmount"
                            placeholder="Enter Amount"
                            required="required"
                            value={withdrawAmount}
                            onChange={(e) => setWithdrawAmount(e.target.value)}
                          />
                        </div>
                        {/* <div class="regi__type">
                            <label for="methodSelect">Payment Method</label>
                            <select class="type__select" id="methodSelect">
                              <option value="method">Payment Method</option>
                              <option value="paypal">Paypal</option>
                              <option value="maestro">Maestro</option>
                              <option value="visa">Visa</option>
                              <option value="express">American Express</option>
                            </select>
                          </div> */}
                        <button
                          type="submit"
                          class="button button--effect mt-2"
                          onClick={withdraw}
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- ==== #dashboard section end ==== --> */}
    </>
  );
};

export default Withdraw;