import React from "react";
import { Header } from "../components/Header";

const ContactUs = () => {
  return (
    <>
      <Header />

      {/* <!-- ==== banner section start ==== --> */}
      <section class="support__banner contact__banner bg__img clear__top"
        data-background="assets/images/contact-banner-bg.png">
        <div class="container">
          <div class="support__banner__area">
            <div class="support__banner__inner">
              <h1 class="neutral-top">How can we help?</h1>
              <h5 class="neutral-top">Got a question?</h5>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #banner section end ==== --> */}

      {/* <!-- ==== contact overview section start ==== --> */}
      <section class="contact__overview">
        <div class="container">
          <div class="contact__overview__area">
            <div class="row">
              <div class="col-md-6 col-xl-4">
                <div class="contact__overview__single column__space--secondary shadow__effect">
                  <img src="assets/images/icons/email.png" alt="email" />
                  <h5>Send Us an Email</h5>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                  <hr />
                  <p class="neutral-bottom">
                    <a href="https://pixner.net/cdn-cgi/l/email-protection#197c61787469757c597c61787469757c377a7674"><span class="__cf_email__" data-cfemail="b4d7dbdac0d5d7c0f4c6d1c2d1c7c09ad7dbd9">[email&#160;protected]</span></a>
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="contact__overview__single column__space--secondary shadow__effect">
                  <img src="assets/images/icons/phone.png" alt="Call" />
                  <h5>Give Us a Call</h5>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                  <hr />
                  <p class="neutral-bottom">
                    <a href="tel:+17087362094">(414) 807 - 7478</a>
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="contact__overview__single shadow__effect">
                  <img src="assets/images/icons/chat.png" alt="Chat" />
                  <h5>Chat with us</h5>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                  <hr />
                  <p class="neutral-bottom">
                    <a href="#0">Open live chat</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #contact overview section end ==== --> */}

      {/* <!-- ==== ask section start ==== --> */}
      <section class="ask section__space bg__img" data-background="assets/images/ask-bg.png">
        <div class="container">
          <div class="ask__area">
            <div class="alert__newsletter__area">
              <div class="section__header">
                <h2 class="neutral-top">Ask a Question</h2>
              </div>
              <form action="#" name="ask__from" method="post">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input input--secondary">
                      <label for="askFirstName">First Name*</label>
                      <input type="text" name="ask__first__name" id="askFirstName"
                        placeholder="Enter Your First Name" required="required" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input input--secondary">
                      <label for="askLastName">Last Name*</label>
                      <input type="text" name="ask__last__name" id="askLastName"
                        placeholder="Enter Your Last Name" required="required" />
                    </div>
                  </div>
                </div>
                <div class="input input--secondary">
                  <label for="askRegistrationMail">Email*</label>
                  <input type="email" name="ask__registration__email" id="askRegistrationMail"
                    placeholder="Enter your email" required="required" />
                </div>
                <div class="input input--secondary input__alt">
                  <label for="askNumber">Phone*</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="number__code__select" id="numberCodeSelectAlert">
                        <option selected value="0">+1</option>
                        <option value="1">+2</option>
                        <option value="2">+3</option>
                        <option value="3">+4</option>
                        <option value="4">+5</option>
                        <option value="5">+6</option>
                      </select>
                    </div>
                    <input type="number" name="ask__number" id="askNumber" required="required"
                      placeholder="345-323-1234" />
                  </div>
                </div>
                <div class="input input--secondary">
                  <label for="askSubject">Subject*</label>
                  <input type="text" name="ask__subject" id="askSubject" placeholder="Write Subject"
                    required="required" />
                </div>
                <div class="input input--secondary">
                  <label for="askMessage">Message*</label>
                  <textarea name="ask_message" id="askMessage" required="required"
                    placeholder="Write Message"></textarea>
                </div>
                <div class="input__button">
                  <button type="submit" class="button button--effect">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #ask section end ==== --> */}

      {/* <!-- ==== faq section start ==== --> */}
      <section class="faq section__space">
        <div class="container">
          <div class="faq__area">
            <div class="section__header">
              <h2 class="neutral-top">Frequently Asked
                Questions</h2>
            </div>
            <div class="faq__group">
              <div class="accordion" id="accordionExampleFund">
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundOne">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundOne" aria-expanded="true"
                      aria-controls="collapseFundOne">
                      What is Revest?
                    </button>
                  </h5>
                  <div id="collapseFundOne" class="accordion-collapse collapse show"
                    aria-labelledby="headingFundOne" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p>combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundTwo">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundTwo" aria-expanded="false"
                      aria-controls="collapseFundTwo">
                      What are the benefits of investing via Revest?
                    </button>
                  </h5>
                  <div id="collapseFundTwo" class="accordion-collapse collapse"
                    aria-labelledby="headingFundTwo" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p>combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundThree">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundThree" aria-expanded="false"
                      aria-controls="collapseFundThree">
                      What makes Revest different?
                    </button>
                  </h5>
                  <div id="collapseFundThree" class="accordion-collapse collapse"
                    aria-labelledby="headingFundThree" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p>combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundFour">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundFour" aria-expanded="false"
                      aria-controls="collapseFundFour">
                      What happens to my investments if Revest goes into bankruptcy?
                    </button>
                  </h5>
                  <div id="collapseFundFour" class="accordion-collapse collapse"
                    aria-labelledby="headingFundFour" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p>combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundFive">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundFive" aria-expanded="false"
                      aria-controls="collapseFundFive">
                      What regulations apply to Revest?
                    </button>
                  </h5>
                  <div id="collapseFundFive" class="accordion-collapse collapse"
                    aria-labelledby="headingFundFive" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p>combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item content__space">
                  <h5 class="accordion-header" id="headingFundSix">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="Fund" />
                    </span>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFundSix" aria-expanded="false"
                      aria-controls="collapseFundSix">
                      How do I Fund investing in Revest?
                    </button>
                  </h5>
                  <div id="collapseFundSix" class="accordion-collapse collapse"
                    aria-labelledby="headingFundSix" data-bs-parent="#accordionExampleFund">
                    <div class="accordion-body">
                      <p class="neutral-top neutral-bottom">combined with a handful of model sentence
                        structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is therefore always free
                        from
                        repetition, injected humour, or</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #faq section end ==== --> */}

      {/* <!-- ==== footer section start ==== --> */}
      <footer class="footer pos__rel over__hi">
        <div class="container">
          <div class="footer__newsletter">
            <div class="row d-flex align-items-center">
              <div class="col-lg-6">
                <div class="footer__newsletter__content column__space">
                  <h3>Subscribe for updates</h3>
                  <p>Stay on top of the latest blog posts, news and announcements</p>
                </div>
              </div>
              <div class="col-lg-6 col-xxl-5 offset-xxl-1">
                <div class="footer__newsletter__form">
                  <form action="#" method="post">
                    <div class="footer__newsletter__input__group">
                      <div class="input">
                        <input type="email" name="news__letter" id="newsLetterMail"
                          placeholder="Enter Your Email" required="required" />
                      </div>
                      <button type="submit" class="button button--effect">Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__area section__space">
            <div class="row">
              <div class="col-md-12 col-lg-4 col-xl-4">
                <div class="footer__intro">
                  <a href="index.html">
                    <img src="assets/images/logo-light.png" alt="Revest" class="logo" />
                  </a>
                  <p>Revest is a platform offering anyone the ability to invest and potentially earn money
                    from property at the click of a button</p>
                  <div class="social">
                    <a href="javascript:void(0)">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div class="footer__links footer__links--alt">
                  <h5>Company</h5>
                  <ul>
                    <li>
                      <a href="about-us.html">About Us</a>
                    </li>
                    <li>
                      <a href="career.html">Careers</a>
                    </li>
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Contact Us</a>
                    </li>
                    <li class="neutral-bottom">
                      <a href="affiliate-program.html">Affiliate</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div class="footer__links footer__links--alt">
                  <h5>Invest</h5>
                  <ul>
                    <li>
                      <a href="properties.html">Browse Properties</a>
                    </li>
                    <li>
                      <a href="how-it-works.html">How it works</a>
                    </li>
                    <li>
                      <a href="loan-application.html">Loan Application </a>
                    </li>
                    <li>
                      <a href="property-alert.html">Property Alerts</a>
                    </li>
                    <li class="neutral-bottom">
                      <a href="support.html">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div class="footer__links footer__links--alt--two">
                  <h5>Insights</h5>
                  <ul>
                    <li>
                      <a href="support.html">Help Center</a>
                    </li>
                    <li>
                      <a href="list-your-property.html">List Your Property</a>
                    </li>
                    <li class="neutral-bottom">
                      <a href="loyality-program.html">Loyality program </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div class="footer__links">
                  <h5>Legal</h5>
                  <ul>
                    <li>
                      <a href="privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="terms-conditions.html">Term & Conditions</a>
                    </li>
                    <li>
                      <a href="cookie-policy.html">Cookie Policy</a>
                    </li>
                    <li class="neutral-bottom">
                      <a href="key-risks.html">Key Risks</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__credit">
            <div class="row d-flex align-items-center">
              <div class="col-sm-9 order-1 order-sm-0">
                <div class="footer__copyright">
                  <p>Copyright &copy; Revest | Designed by <a
                    href="https://themeforest.net/user/pixelaxis">Pixelaxis</a></p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="footer__language">
                  <select class="language-select">
                    <option value="english">En</option>
                    <option value="australia">Aus</option>
                    <option value="brazil">Bra</option>
                    <option value="argentina">Arg</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer__animation">
          <img src="assets/images/footer/footer__left__circle.png" alt="Circle" class="left__circle" />
          <img src="assets/images/footer/footer__right__circle.png" alt="Circle" class="right__circle" />
          <img src="assets/images/footer/footer__home___illustration.png" alt="Home" class="home__illustration" />
        </div>
      </footer>
      {/* <!-- ==== #footer section end ==== --> */}

      {/* <!-- Scroll To Top --> */}
      <a href="javascript:void(0)" class="scrollToTop">
        <i class="fa-solid fa-angles-up"></i>
      </a>

    </>
  );
};

export default ContactUs;