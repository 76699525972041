import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { ReactComponent as HeroSVG } from './revenue-animate.svg';
import HeroSVG from "./revenue-animate.svg";
import { useSelector } from "react-redux";

const Home = () => {
  const user = useSelector((state) => state.main.user);

  const handleLogin = () => {
    if (user) {
      window.location.href = "/profile";
    } else {
      window.location.href = "/register";
    }
  };

  return (
    <>
      {/* <!-- ==== header start ==== --> */}
      <Header />
      {/* <!-- ==== #header end ==== --> */}

      {/* <!-- ==== hero section start ==== --> */}
      <section
        className="hero hero--two pos__rel over__hi bg__img"
        data-background="/assets/images/hero/hero-background.png"
      >
        <div className="container">
          <div className="hero__area">
            <div className="row">
              <div className="col-lg-7 col-xxl-7">
                <div className="hero__content">
                  <h5 className="neutral-top" style={{ color: "red" }}>
                    Simple. Memorable. Accessible.
                  </h5>
                  <h4>Discover Privilege Card</h4>
                  <h2>
                    <span>
                      Welcome to Titos Global Unlock with Privilege Card
                    </span>
                  </h2>
                  <p className="primary neutral-bottom">
                    45+ years in Hospitality Clubbing. Explore exclusive Titos
                    Global Membership for exceptional moments.
                  </p>
                  <div className="hero__cta__group">
                    <a
                      href="/loyalty-program"
                      className="button button--effect"
                    >
                      Get Here
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="button button--secondary button--effect"
                      onClick={handleLogin}
                    >
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xxl-5">
                {/* <div className="hero__illustration d-none d-lg-block"> */}
                <img
                  className="hero-img"
                  src={HeroSVG}
                  alt="Hero Illustration"
                  style={{
                    background:
                      "url('assets/images/hero/hero-AnimationEffect.svg')",
                    width: "35rem",
                    height: "35rem",
                  }}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #hero section end ==== --> */}

      {/* <!-- ==== about overview section start ==== --> */}
      <section className="about__overview" id="aboutus">
        <div className="video video--secondary">
          <div className="container">
            <div className="video__area">
              <img
                src="assets/images/about-video-illustration.jpg"
                alt="Video Illustration"
              />
              <div className="video__btn">
                <a
                  className="video__popup"
                  href="./assets/video/Titos.mp4"
                  target="_blank"
                  title="YouTube video player"
                >
                  <i className="fa-solid fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="about__overview__area">
            <div className="section__header">
              <h5 className="neutral-top">
                TITO’S PRIVILEGE CARD TRANSFORMS YOUR NIGHTS
              </h5>
              <h2>
                Introducing Goa's Premier Club: Enchanting Music, Exclusive
                TITO’s Privilege Loyalty Program
              </h2>
              <p className="mw-100">
                At Titos Global, we blend hospitality, local culture, and
                lifestyle to create an unparalleled membership experience. Our
                journey began in 1971, a year when Goa's locals, hippies, and
                young travelers from Western Europe and America gathered to
                embrace the beauty of the Goan coastline. They shared meals,
                reveled in the soothing sound of the waves, and basked in the
                tranquility of Baga Beach. This beauty and serenity inspired
                Tito Henry De Souza, an avid traveler, to establish a gathering
                place for expats and like-minded individuals.
              </p>
              <p className="mw-100">
                Join Titos’s Privilage Card today : Endless possibilities,
                premium privileges, unparalleled connections—beyond clubbing, an
                extraordinary journey !
              </p>
            </div>
            <div className="portfolio__overview">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="portfolio__overview__single column__space">
                    <img
                      src="assets/images/icons/parties.png"
                      alt="Investors"
                    />
                    <div>
                      <h2 className="mb-0">
                        <span className="counterTwo">26500</span>
                      </h2>
                      <div>Parties till date</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="portfolio__overview__single column__space">
                    <img
                      src="assets/images/icons/visitors.png"
                      alt="completed"
                    />
                    <div>
                      <h2 className="mb-0">
                        <span className="counterTwo">90000</span>
                      </h2>
                      <div>Visitors per month</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="portfolio__overview__single">
                    <img
                      src="assets/images/icons/residants.png"
                      alt="Average Annual Return"
                    />
                    <div>
                      <h2 className="mb-0">
                        <span className="counterTwo">87600</span>
                      </h2>
                      <div>Hours of Music were played</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #about overview section end ==== --> */}

      {/* <!-- ==== image gallery section start ==== --> */}
      <div className="image__gallery section__space">
        <div className="image__gallery__area">
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/1.jpg">
              <img
                src="assets/images/homepage-gallery/1.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/2.jpg">
              <img
                src="assets/images/homepage-gallery/2.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/3.jpg">
              <img
                src="assets/images/homepage-gallery/3.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/4.jpg">
              <img
                src="assets/images/homepage-gallery/4.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/5.jpg">
              <img
                src="assets/images/homepage-gallery/5.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/6.jpg">
              <img
                src="assets/images/homepage-gallery/6.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
          <div className="gallery__single">
            <a href="assets/images/homepage-gallery/7.jpg">
              <img
                src="assets/images/homepage-gallery/7.jpg"
                alt="Proect Image"
              />
            </a>
          </div>
        </div>
      </div>
      {/* <!-- ==== #image gallery section end ==== --> */}

      {/* <!-- ==== team section start ==== --> */}
      <section className="team section__space__bottom" id="team">
        <div className="container">
          <div className="team__area wow fadeInUp">
            <div className="section__header">
              <h5 className="neutral-top">
                Bringing people together from all over the world
              </h5>
              <h2>We are a dynamic team of hospitality</h2>
              <p className="neutral-bottom mw-100">
                The year was 1971. It was the time of the hippies and the flower
                power generation. The hippies and other young people from
                Western Europe and the Americas were searching for Nirvana or
                Spiritual Salvation, and Goa seemed to put them on the right
                track. The Goans also accepted these peaceful and gentle people
                wholeheartedly. Life in Goa was simple then. No motorbikes,
                cars, and restaurants. It was here and in this environment that
                Tito Henry De Souza, who had traveled extensively, decided to
                settle down and also provide a place for all expats to meet up.
              </p>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="team__single shadow__effect">
                  <div className="team__thumb">
                    <img
                      src="assets/images/team/ricardo-dsouza.jpg"
                      alt="Paul"
                    />
                    <a href="#0">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                  <h5 className="neutral-top">Ricardo D'Souza</h5>
                  <p className="neutral-bottom">Director</p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="team__single shadow__effect">
                  <div className="team__thumb">
                    <img
                      src="assets/images/team/david-dsouza.jpg"
                      alt="Ryan Jackson"
                    />
                    <a href="#0">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                  <h5 className="neutral-top">David D'Souza</h5>
                  <p className="neutral-bottom">Director</p>
                </div>
              </div>
              {/* <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="team__single shadow__effect">
                  <div className="team__thumb">
                    <img src="assets/images/team/rosen.png" alt="Brittany Rosen" />
                    <a href="#0">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                  <h5 className="neutral-top">Brittany Rosen</h5>
                  <p className="neutral-bottom">VP, Finance and Strategy</p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="team__single shadow__effect">
                  <div className="team__thumb">
                    <img src="assets/images/team/robert.png" alt="Robert Henriks" />
                    <a href="#0">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                  <h5 className="neutral-top">Robert Henriks</h5>
                  <p className="neutral-bottom">Senior Developer</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #team section end ==== --> */}

      {/* <!-- ==== market section start ==== --> */}
      <section
        className="market market--two market--three section__space__bottom"
        id="whatwedo"
        data-background="./assets/images/background-footer.jpg"
      >
        <div className="container">
          <div
            className="market__area market__area--two section__space bg__img"
            data-background="assets/images/light-two.png"
          >
            <div className="row d-flex align-items-center">
              <div className="col-lg-7">
                <div className="content">
                  <h5 className="neutral-top">What we do?</h5>
                  <h2>Elevate Entertainment</h2>
                  <p>
                    Nestled on the renowned Tito's Lane, Club Tito's transcends
                    traditional nightlife. It's more than just a charming venue
                    illuminated by pretty lights and exquisite décor; it's a hub
                    for socializing, cocktails, delectable meals, and exclusive
                    benefits await Titos Global members.
                  </p>
                  <a href="/loyalty-program" className="button button--effect">
                    Get Here
                  </a>
                  <img src="assets/images/arrow.png" alt="Go to" />
                </div>
              </div>
            </div>
            <img
              src="assets/images/market-two-illustration.svg"
              alt="Explore the Market"
              className="d-none d-lg-block market__two__thumb"
            />
          </div>
          <div className="market__features">
            <div className="row">
              <div className="col-md-6 col-xl-4">
                <div className="market__features__single shadow__effect__secondary">
                  <img src="assets/images/icons/view.png" alt="Gain Instant" />
                  <h4>THE VIEW</h4>
                  <p className="neutral-bottom">
                    Ocean views, Tito's Lane, shops, pubs, locals. Steps from
                    the beach, positive vibes all night.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="market__features__single market__features__single--alt shadow__effect">
                  <img
                    src="assets/images/icons/cocktail.png"
                    alt="Get noticed"
                  />
                  <h4>COCKTAILS</h4>
                  <p className="neutral-bottom">
                    Local and international spirits, fruits, herbs, and
                    infusions crafted into extraordinary cocktails and
                    mocktails.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="market__features__single alt shadow__effect__secondary">
                  <img
                    src="assets/images/icons/dinner.png"
                    alt="Focus on Deals"
                  />
                  <h4>CULINARY EXPERIENCE</h4>
                  <p className="neutral-bottom">
                    Exquisite Indian, Goan, and Continental cuisines for
                    memorable dining experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #market section end ==== --> */}

      {/* <!-- ==== start step section start ==== --> */}
      <section className="start section__space__top" id="location">
        <div className="container">
          <div className="start__area wow fadeInUp">
            <div className="section__header">
              <h5 className="neutral-top">OUR LOCATIONS</h5>
              <h2>The best Nightclubs in Goa</h2>
              <p className="neutral-bottom">
                Tito's clubs are the most visited place in Goa for all
                influencers, bloggers, and local and international celebrities
                along with movie stars, VIPs, high-ranking politicians, and so
                on.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 col-xl-4">
                <div className="start__single__item column__space--secondary">
                  <div className="img__box">
                    <img
                      src="/assets/images/step/club-titos.jpg"
                      alt="Browse Properties"
                    />
                    <div className="step__count">
                      <h4>01</h4>
                    </div>
                  </div>
                  <h4>CLUB TITO'S</h4>
                  <p className="neutral-bottom">
                    Vibrant nightlife venue with groovy music, soft lighting,
                    exquisite dining, and enticing cocktails for dancing and
                    entertainment.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="start__single__item column__space--secondary">
                  <div className="img__box arrow__container">
                    <img
                      src="/assets/images/step/cafe-mambo.jpg"
                      alt="View Details & Invest"
                    />
                    <div className="step__count">
                      <h4>02</h4>
                    </div>
                  </div>
                  <h4>CAFÉ MAMBO</h4>
                  <p className="neutral-bottom">
                    Lively nightclub with music, dancing, delicious food,
                    drinks, and a buzzing atmosphere that attracts eager
                    partygoers.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="start__single__item">
                  <div className="img__box">
                    <img
                      src="/assets/images/step/titos-arena.jpg"
                      alt="Earn and Track"
                    />
                    <div className="step__count">
                      <h4>03</h4>
                    </div>
                  </div>
                  <h4>TITO'S ARENA</h4>
                  <p className="neutral-bottom">
                    Ultimate beachside party lounge with international DJs,
                    stunning ambiance, and top-notch entertainment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #start step section end ==== --> */}

      {/* <!-- ==== why invest section start ==== --> */}
      <section className="why__invest section__space" id="servies">
        <div className="container">
          <div className="why__invest__area wow fadeInUp">
            <div className="row d-flex align-items-center">
              <div className="col-xxl-6">
                <div className="content column__space--secondary">
                  <h5 className="neutral-top">
                    DISCOVER THE MEMBERSHIP ADVANTAGE WITH TITOS GLOBAL
                  </h5>
                  <h2>What We Offer</h2>
                  <p>
                    Easily one of the most recognized names among nightclubs in
                    India; Tito's in Baga is a beloved carousing spot for
                    vacationers in Goa. Our legacy is built on providing
                    extraordinary experiences to our cherished members.
                  </p>
                </div>
              </div>
              <div className="col-xxl-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="why__invest__single shadow__effect__secondary">
                      <img
                        src="/assets/images/icons/investment.jpg"
                        alt="Passive"
                      />
                      <h5>Premier Nightlife Access</h5>
                      <p className="neutral-bottom">
                        Experience the vibrant ambiance, world-class
                        entertainment, and unmatched hospitality of our esteemed
                        nightclubs with complimentary lifetime access.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="why__invest__single shadow__effect__secondary">
                      <img
                        src="/assets/images/icons/Seabreeze-spirit.jpg"
                        alt="Stable Cash Flow"
                      />
                      <h5>Generous Drink Discounts</h5>
                      <p className="neutral-bottom">
                        Revel in the electrifying atmosphere of our nightclubs
                        while enjoying a 25% discount on all beverages at our
                        venues.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xxl-3">
                <div className="why__invest__single shadow__effect__secondary">
                  <img
                    src="/assets/images/icons/global-solution.jpg"
                    alt="Tax Advantages"
                  />
                  <h5>Titos Goa Event Access</h5>
                  <p className="neutral-bottom">
                    Join and attend Tito's Goa Events to unlock a spectrum of
                    benefits with Tito's Privilege Loyalty Program.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-xxl-3">
                <div className="why__invest__single shadow__effect__secondary">
                  <img
                    src="/assets/images/icons/clubbing.jpg"
                    alt="Capital Appr'n"
                  />
                  <h5>Loyalty Program</h5>
                  <p className="neutral-bottom">
                    Tito's Privilege Card: INR 5,000 for lifetime access,
                    discounts, and exclusive benefits. Terms apply.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-xxl-3">
                <div className="why__invest__single shadow__effect__secondary">
                  <img
                    src="/assets/images/icons/finance-management.jpg"
                    alt="Inflation protection"
                  />
                  <h5>Rewards Point</h5>
                  <p className="neutral-bottom">
                    Tito's Rewards: Earn points on purchases for exclusive
                    benefits. Embrace privileges. Terms apply.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-xxl-3">
                <div className="why__invest__single shadow__effect__secondary">
                  <img
                    src="/assets/images/icons/restaurant.jpg"
                    alt="Diversification"
                  />
                  <h5>Exclusive Savings</h5>
                  <p className="neutral-bottom">
                    Cardholders enjoy 15% off food and 25% off beverages at
                    Tito's clubs. Additional partner discounts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #why invest section end ==== --> */}

      {/* <!-- ==== community section start ==== --> */}
      <section
        className="community section__space__top over__hi bg__img"
        data-background="/assets/images/community-bg.png"
      >
        <div className="container">
          <div className="community__area">
            <div className="section__header">
              <h5 className="neutral-top">
                MEMBERSHIP EXCLUSIVE—BOOK NOW, CELEBRATE LATER
              </h5>
              <h2>Reserve Your Spot and Elevate Your Nights</h2>
              <p className="neutral-bottom">
                Unleash your nocturnal spirit and experience a membership-driven
                nightlife like never before.
              </p>
            </div>
            <div className="comunity-wrapper section__space">
              <div className="buttons">
                <a href="/loyalty-program" className="button button--effect">
                  Buy Now
                </a>
              </div>
              <div className="comunity-area">
                <div className="comunity-item">
                  <img src="/assets/images/community/1.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/2.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/3.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/4.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/5.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/6.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/7.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/8.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/9.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/10.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/11.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/12.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/13.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/14.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/15.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/16.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/17.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/18.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/19.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/22.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/20.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/21.png" alt="comunity" />
                </div>
              </div>
              <div className="comunity-area two">
                <div className="comunity-item">
                  <img src="/assets/images/community/1.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/2.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/3.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/4.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/5.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/6.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/7.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/8.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/9.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/10.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/11.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/12.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/13.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/14.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/15.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/16.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/17.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/18.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/19.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/22.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/20.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/21.png" alt="comunity" />
                </div>
              </div>
              <div className="comunity-area three">
                <div className="comunity-item">
                  <img src="/assets/images/community/1.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/2.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/3.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/4.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/5.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/6.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/7.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/8.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/9.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/10.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/11.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/12.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/13.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/14.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/15.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/16.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/17.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/18.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/19.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/22.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/20.png" alt="comunity" />
                </div>
                <div className="comunity-item">
                  <img src="/assets/images/community/21.png" alt="comunity" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #community section end ==== --> */}

      {/* <!-- ==== testimonial section start ==== --> */}
      <section
        className="testimonial testimonial--two section__space pos__rel over__hi bg__img"
        data-background="/assets/images/testimonial/dot-map.png"
      >
        <div className="container">
          <div className="testimonial__area">
            <div className="section__header">
              <h5 className="neutral-top">
                EXPERIENCE MEMBERS-ONLY NIGHTS AT TITO'S CLUB IN GOA!
              </h5>
              <h2>Trusted by Over 2, 90,978 people worldwide since 1971 </h2>
              <p className="neutral-bottom">
                Join a global community of over two million members who have
                trusted us since 1971 to curate exceptional nights at Tito's
                Club. Discover a world where music, energy, and top-notch
                service converge for an unparalleled membership experience in
                Goa.
              </p>
            </div>
            <div className="testimonial__item__wrapper">
              <div className="testimonial__support">
                <div
                  className="testimonial__item bg__img"
                  data-background="/assets/images/testimonial/quote.png"
                >
                  <div className="testimonial__author__ratings">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p className="tertiary">
                    Very trustworthy and clearly platform to invest in real
                    state. Safe investment with monthly payouts. Really
                    recommended!
                  </p>
                  <div className="testimonial__author">
                    <div className="testimonial__author__info">
                      <div className="avatar__wrapper">
                        <img
                          src="/assets/images/testimonial/avatar.png"
                          alt="Allan Murphy"
                        />
                      </div>
                      <div>
                        <h5>Allan Murphy</h5>
                        <p className="neutral-bottom">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial__support">
                <div
                  className="testimonial__item bg__img"
                  data-background="/assets/images/testimonial/quote.png"
                >
                  <div className="testimonial__author__ratings">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p className="tertiary">
                    Very trustworthy and clearly platform to invest in real
                    state. Safe investment with monthly payouts. Really
                    recommended!
                  </p>
                  <div className="testimonial__author">
                    <div className="testimonial__author__info">
                      <div className="avatar__wrapper">
                        <img
                          src="/assets/images/testimonial/avatar.png"
                          alt="Allan Murphy"
                        />
                      </div>
                      <div>
                        <h5>Allan Murphy</h5>
                        <p className="neutral-bottom">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial__support">
                <div
                  className="testimonial__item bg__img"
                  data-background="/assets/images/testimonial/quote.png"
                >
                  <div className="testimonial__author__ratings">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p className="tertiary">
                    Very trustworthy and clearly platform to invest in real
                    state. Safe investment with monthly payouts. Really
                    recommended!
                  </p>
                  <div className="testimonial__author">
                    <div className="testimonial__author__info">
                      <div className="avatar__wrapper">
                        <img
                          src="/assets/images/testimonial/avatar.png"
                          alt="Allan Murphy"
                        />
                      </div>
                      <div>
                        <h5>Allan Murphy</h5>
                        <p className="neutral-bottom">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial__support">
                <div
                  className="testimonial__item bg__img"
                  data-background="/assets/images/testimonial/quote.png"
                >
                  <div className="testimonial__author__ratings">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p className="tertiary">
                    Very trustworthy and clearly platform to invest in real
                    state. Safe investment with monthly payouts. Really
                    recommended!
                  </p>
                  <div className="testimonial__author">
                    <div className="testimonial__author__info">
                      <div className="avatar__wrapper">
                        <img
                          src="/assets/images/testimonial/avatar.png"
                          alt="Allan Murphy"
                        />
                      </div>
                      <div>
                        <h5>Allan Murphy</h5>
                        <p className="neutral-bottom">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial__support">
                <div
                  className="testimonial__item bg__img"
                  data-background="/assets/images/testimonial/quote.png"
                >
                  <div className="testimonial__author__ratings">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p className="tertiary">
                    Very trustworthy and clearly platform to invest in real
                    state. Safe investment with monthly payouts. Really
                    recommended!
                  </p>
                  <div className="testimonial__author">
                    <div className="testimonial__author__info">
                      <div className="avatar__wrapper">
                        <img
                          src="/assets/images/testimonial/avatar.png"
                          alt="Allan Murphy"
                        />
                      </div>
                      <div>
                        <h5>Allan Murphy</h5>
                        <p className="neutral-bottom">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==== #testimonial section end ==== --> */}

      {/* <!-- ==== footer section start ==== --> */}
      <footer
        className="footer pos__rel over__hi"
        data-background="./assets/images/background-footer.jpg"
      >
        <div className="container">
          <div className="footer__newsletter">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="footer__newsletter__content column__space">
                  <h3>Subscribe for updates</h3>
                  <p>
                    Stay on top of the latest blog posts, news and announcements
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-xxl-5 offset-xxl-1">
                <div className="footer__newsletter__form">
                  <form action="#" method="post">
                    <div className="footer__newsletter__input__group">
                      <div className="input">
                        <input
                          type="email"
                          name="news__letter"
                          id="newsLetterMail"
                          placeholder="Enter Your Email"
                          required="required"
                        />
                      </div>
                      <button type="submit" className="button button--effect">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__area section__space">
            <div className="row">
              <div className="col-md-12 col-lg-5 col-xl-5">
                <div className="footer__intro">
                  <a href="#">
                    <img
                      src="/assets/images/titos-white.png"
                      alt="Titos-logo"
                      className="logo"
                    />
                  </a>
                  <p>
                    Tito's is one of the most popular nightclubs in Goa, located
                    in the beach town of Baga. It has been a popular party spot
                    for both locals and tourists for several decades. Tito's is
                    known for its energetic atmosphere, lively music, and
                    diverse crowd. The club has several dance floors, bars, and
                    a pool area.
                  </p>
                  <div className="social">
                    <a href="/">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-1 col-lg-1 col-xl-1" />
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Company</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="#aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="#team">Our Team</a>
                    </li>
                    <li>
                      <a href="#whatwedo">What we do?</a>
                    </li>
                    <li>
                      <a href="#location">Our Locations</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="#servies">Services We Offer</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links footer__links--alt">
                  <h5>Loyalty Program</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="/loyalty-program#coupon">Buy Now</a>
                    </li>
                    <li>
                      <a href="/loyalty-program#howitworks">How it works</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="/loyalty-program#faq">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                <div className="footer__links">
                  <h5>Legal</h5>
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <a href="javascript:void(0)">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Term & Conditions</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Cookie Policy</a>
                    </li>
                    <li className="neutral-bottom">
                      <a href="javascript:void(0)">Key Risks</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__credit">
            <div className="row d-flex align-items-center">
              <div className="col-sm-9 order-1 order-sm-0 w-100">
                <div className="footer__copyright">
                  <p className="text-center">Copyright &copy; Titos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ==== #footer section end ==== --> */}

      {/* <!-- Scroll To Top --> */}
      <a href="/" className="scrollToTop">
        <i className="fa-solid fa-angles-up"></i>
      </a>
    </>
  );
};

export default Home;
