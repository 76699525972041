import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const Register = () => {
  const [sponsor, setSponsor] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [errors, setErrors] = useState({});
  const [isOTPEmailSent, setOTPEmailSent] = useState(false);

  const [params] = useSearchParams();

  useEffect(() => {
    setSponsor(params.get('referId'));
  }, [])

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleInputOTP = (event) => {
    setOTP(event.target.value);
  };

  const getOTP = async () => {
    if (email) {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/signup`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'email': email, 'refer_user': sponsor }),
        });
        const data = await res.json();
        setErrors({ ...errors, email: null });
        if (data.status) {
          setOTPEmailSent(true);
          toast.success("OTP sent successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.code === 400) {
          setErrors({
            ...errors,
            email: "Entered email is invalid. Please check.",
          });
        } else {
          toast.error("Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      toast.error('Please enter Email');
    }
  };

  const submitOTP = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROOT_URL}account/confirm-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email, 'email_otp': otp }),
      });
      const data = await res.json();
      console.log(data);
      if (data.status) {
        const user = data.data.user
        console.log(user);
        localStorage.setItem("titos-user", JSON.stringify(user));
        localStorage.setItem('titos-token', data.data.access);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      } else {
        toast.error(
          data?.message || "Please try after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message || "Please try after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div
      className="wrapper bg__img d-flex justify-content-around"
      data-background='./assets/images/registration-bg.jpg'
    >
      <Header />
      <div className="registration_animation">
        <img src="./assets/images/enter-otp-animate.svg" />
      </div>
      <section className="registration clear__top">
        <div className="container">
          <div className="registration__area">
            <h4 className="neutral-top">Register</h4>
            <form name="login__form" className="form__login">
              <div className="input input--secondary">
                <label htmlFor="loginMail">Sponsor Email</label>
                <input
                  value={sponsor}
                  type="email"
                  name="login__email"
                  id="loginMail"
                  placeholder="Enter sponsor email"
                  required
                  onChange={(e) => setSponsor(e.target.value)}
                />
              </div>
              <div className="input input--secondary">
                <label htmlFor="loginMail">Email*</label>
                <input
                  value={email}
                  type="email"
                  name="login__email"
                  id="loginMail"
                  placeholder="Enter your email"
                  required
                  onChange={handleInputEmail}
                />
              </div>
              {isOTPEmailSent ? (
                <div className="input input--secondary">
                  <label htmlFor="loginPass">OTP*</label>
                  <input
                    type="otp"
                    name="login__pass"
                    id="loginPass"
                    placeholder="OTP"
                    required
                    onChange={handleInputOTP}
                  />
                </div>
              ) : null}
              <div className="input__button">
                {isOTPEmailSent ? (
                  <button
                    type="button"
                    className="button button--effect mt-1"
                    onClick={submitOTP}
                  >
                    Register
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button button--effect mt-1"
                    onClick={getOTP}
                  >
                    Continue
                  </button>
                )}
              </div>
              <div className="mt-4">Already have an account ? <a href="/login" className="text-decoration-underline text-danger">Log in</a></div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;